import React from 'react';
import {Link} from "@reach/router";
import Logo from './images/craft-ottawa-logo.svg';
import ContactUs from './ContactUs.js';
import ContactIcon from './images/contact-us.svg';
import NewsIcon from './images/news.svg';
import TwitterIcon from './images/twitter.svg';

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false
        };
    }

    toggleModal = () => {
        this.setState(state => ({
            modalVisible: !state.modalVisible
        }));
    };

    render() {
        const {modalVisible} = this.state;

        return (
            <div className='header'>
                <ul className='menu' ref={this.menu}>
                    <img src={Logo} className='logo-image' alt='Craft Ottawa Mid-World Technologies Logo'/>
                    <span className='logo site-title'></span>
                    <Link to='/'>Map</Link>
                    <Link to='/events'>Calendar</Link>
                    <button title='Email' type='button' onClick={this.toggleModal}><img src={ContactIcon} alt='icon link to email address contact@craftottawa.ca' className='social-link-image'/></button>
                        {modalVisible && (<ContactUs onHide={this.toggleModal}/>)}
                        <a className='social-link' title='Twitter @craft_ottawa' href='https://twitter.com/craft_ottawa' rel='author noopener noreferrer' target='_blank'>
                            <img src={TwitterIcon} alt="icon link to the Craft Ottawa Twitter account" className='social-link-image'/>
                        </a>
                        <Link title='Press &#38; Publicity' className='social-link' to='/press' rel='author'><img src={NewsIcon} alt='megaphone icon link to the Craft Ottawa Press and Publicity Page' className='social-link-image'/></Link>
                </ul>
            </div>
        );
    }
}

export default Nav;