import React from 'react';
import {Router, Link} from '@reach/router';
import {Helmet} from 'react-helmet';
import Nav from './Nav.js';
import MapComponent from './MapComponent.js';
import Events from './Events.js';
import Press from './Press.js';
import AddEvents from './AddEvents.js';
import SubmitEvent from './SubmitEvent.js';
import Footer from './Footer.js';

class App extends React.Component {
    render() {
        const NotFound = () => (
            <div id='notfound' className='container'>
                <div className='notfound'>
                    <div className='notfound-404'>
                        <h1>404</h1>
                    </div>
                    <h2>The page you are looking for cannot be found</h2>
                    <Link className='arrow site-title' to='/'>Return to the Map</Link>
                </div>
            </div>
        )

        return (
            <div className='App'>
                <Helmet>
                    <title>Craft Ottawa</title>
                    <meta
                    name="description"
                    content="The Ottawa Valley is home to a vibrant local craft liquor scene: Find your brewery, distillery, winery, vineyard, cidery, meadery, events, and festivals!"/>
                    <meta property="og:title" content="Craft Ottawa"/>
                    <meta
                        property="og:description"
                        content="The Ottawa Valley is home to a vibrant local craft liquor scene: Find your brewery, distillery, winery, vineyard, cidery, meadery, events, and festivals!"/>
                </Helmet>
                <Nav/>
                <Router basepath="/">
                    <MapComponent path="/"/>
                    <Events path="events"/>
                    <Press path="press"/>
                    <AddEvents path="addevents"/>
                    <SubmitEvent path="submitevent"/>
                    <NotFound default/>
                </Router>
                <Footer/>
            </div>
        );
    }
}

export default App;