import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import FacebookIcon from './images/facebook.svg';
import CraftOttawaLaunchPressReleasePDF from './pdf/Craft-Ottawa-Launch-Press-Release.pdf';
import CraftOttawaLaunchPressReleaseAug132020Thumbnail from './pdf/Craft Ottawa Press Release - August 13th, 2020-1.png';
import HummailBeerWhereHereInterviewWithDanielPostPDF from './pdf/Hummail-Beer-Where-Here-interview-with-Daniel-Post-August-20-2020.pdf';
import HummailBeerWhereHereInterviewWithDanielPostThumbnail from './pdf/Hummail — Beer Where Here interview with Daniel Post — August 20, 2020-1.png';
import FACESMagJun112020Thumbnail from './pdf/facesmag.ca June 11, 2020.png';
import MillstoneNewsJun252020Thumbnail from './pdf/millstonenews.com June 25, 2020.png';

class Press extends React.Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Craft Ottawa - Press and Publicity</title>
                    <meta
                        name="description"
                        content="Craft Ottawa is always reaching for new heights! Check out our press releases and publicity."/>
                    <meta property="og:title" content="Craft Ottawa - Press and Publicity"/>
                    <meta
                        property="og:description"
                        content="Craft Ottawa is always reaching for new heights! Check out our press releases and publicity."/>
                </Helmet>
                <div id='press-and-publicity' className='container'>
                    <div className='heading'>Press &#38; Publicity</div>
                    <br/>
                    <div id='press-releases-and-articles'>
                        <div className="row centered">
                        <div className="col-sm brightness">
                                <a className='press-link' title='Hummail Interview' href={HummailBeerWhereHereInterviewWithDanielPostPDF} rel='noopener noreferrer' target='_blank'>
                                    <img src={HummailBeerWhereHereInterviewWithDanielPostThumbnail} alt="Thumbnail preview of Hummail interview with Daniel Post" className='press-thumbnail'/>
                                    <p className='caption'>Hummail Interview - Aug 20th, 2020</p>
                                </a>
                            </div>
                            <div className="col-sm brightness">
                                <a className='press-link' title='PR Aug 13th 2020' href={CraftOttawaLaunchPressReleasePDF} rel='author noopener noreferrer' target='_blank'>
                                    <img src={CraftOttawaLaunchPressReleaseAug132020Thumbnail} alt="Thumbnail preview of Craft Ottawa press release from August 13th 2020" className='press-thumbnail'/>
                                    <p className='caption highlight'>Press Release - Aug 13th, 2020</p>
                                </a>
                            </div>
                            <div className="col-sm brightness">
                                <a className='press-link' title='The Millstone' href='https://millstonenews.com/almonte-web-developer-creates-local-guide-to-craft-beverages/' rel='external noopener noreferrer' target='_blank'>
                                    <img src={MillstoneNewsJun252020Thumbnail} alt="Thumbnail preview of The Millstone post about Craft Ottawa from June 25th 2020" className='press-thumbnail'/>
                                    <p className='caption'>The Millstone - Jun 25th, 2020</p>
                                </a>
                            </div>
                            <div className="col-sm brightness">
                                <a className='press-link' title='FACES Magazine' href='https://facesmag.ca/craftottawa-a-comprehensive-map-of-craft-makers-in-the-ottawa-region/' rel='external noopener noreferrer' target='_blank'>
                                    <img src={FACESMagJun112020Thumbnail} alt="Thumbnail preview of FACES Magazine announcement of Craft Ottawa from June 11th 2020" className='press-thumbnail'/>
                                    <p className='caption'>FACES Magazine - Jun 11th, 2020</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id='facebook-posts'>
                        <ul className="tilesWrap">
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> Lanark County Tourism</h3>
                                <div>
                                    <p>Just launched, a new website created by Almonte entrepreneur Daniel Post, that maps out all the craft breweries in the Ottawa region...</p>
                                    <p>Posted by <a href="https://www.facebook.com/LanarkCountyTourism/" rel='external noopener noreferrer' target='_blank'>Lanark County Tourism</a> on Thursday, 20 August 2020.</p>
                                    <a href="https://www.facebook.com/LanarkCountyTourism/posts/10164137686085644" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> The Heart of Orléans BIA</h3>
                                <div>
                                    <p>Are you an Ottawa Valley resident and looking for craft beer location in your area? Check the website of Daniel Post...</p>
                                    <p>Posted by <a href="https://www.facebook.com/HeartOfOrleans/" rel='external noopener noreferrer' target='_blank'>The Heart of Orléans BIA</a> on Monday, 17 August 2020.</p>
                                    <a href="https://www.facebook.com/HeartOfOrleans/posts/3503305156355634"  rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> Harrowsmith</h3>
                                <div>
                                    <p>Meet web developer Daniel Post. Daniel lives in the Ottawa Valley, and in June he launched a new website...</p>
                                    <p>Posted by <a href="https://www.facebook.com/harrowsmithmagazine/" rel='external noopener noreferrer' target='_blank'>Harrowsmith</a> on Saturday, 15 August 2020.</p>
                                    <a href="https://www.facebook.com/harrowsmithmagazine/posts/3553774664635775" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> Wining with Mel</h3>
                                <div>
                                    <p>Check it out! Craft Ottawa has put all of the Ottawa Valley&#039;s wineries, breweries, distilleries, meaderies and cideries in one convenient map! It even has bicycle routes!...</p>
                                    <p>Posted by <a href="https://www.facebook.com/winingwithmel/" rel='external noopener noreferrer' target='_blank'>Wining with Mel</a> on Thursday, 6 August 2020.</p>
                                    <a href="https://www.facebook.com/winingwithmel/posts/163259458700756" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> CTA: Culinary Tourism Alliance</h3>
                                <div>
                                    <p>Attention beer, wine and cider lovers! There&#039;s a new resource for all things craft in the Ottawa region. Check it out <span role='img' aria-label='Eyes'>👀</span> #traveltomorrow #discoveron</p>
                                    <p>Posted by <a href="https://www.facebook.com/ontarioculinary/" rel='external noopener noreferrer' target='_blank'>CTA: Culinary Tourism Alliance</a> on Monday, 22 June 2020.</p>
                                    <a href="https://www.facebook.com/ontarioculinary/posts/10158849736274701" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> Smokie Ridge Vineyard</h3>
                                <div>
                                    <p>Are you looking for a new winery or craft brewery? Check out this map. It shows all the little known gems, including us, that you can explore. Support local!</p>
                                    <p>Posted by <a href="https://www.facebook.com/smokieridge/" rel='external noopener noreferrer' target='_blank'>Smokie Ridge Vineyard</a> on Friday, 12 June 2020.</p>
                                    <a href="https://www.facebook.com/smokieridge/posts/4175872455771043" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                            <li>
                                <h3><img src={FacebookIcon} alt="Facebook icon" className='social-link-image'/> Vignoble Clos du Vully</h3>
                                <div>
                                    <p>Fantastic new interactive map of all the local beer/wine/spirit businesses in eastern Ontario! https://craftottawa.ca/</p>
                                    <p>Posted by <a href="https://www.facebook.com/vignobleclosduvully/" rel='external noopener noreferrer' target='_blank'>Vignoble Clos du Vully</a> on Thursday, 11 June 2020.</p>
                                    <a href="https://www.facebook.com/vignobleclosduvully/posts/2938671416250777" rel='external noopener noreferrer' target='_blank'>Full Post</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Press;