import React from 'react';

class ScrollToTopButton extends React.Component {
    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <div className='go-to-top'>
                <button onClick={() => this.scrollToTop()} id='top-icon' title="Go To Top">Top&nbsp;↑</button>
            </div>
        );
    }
}

export default ScrollToTopButton;