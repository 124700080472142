import React from 'react';
import { Link } from "@reach/router";
import AriaModal from 'react-aria-modal';
import { PropTypes } from 'prop-types';

const ContactUs = ({onHide}) => (
    <AriaModal
        titleId="contact-us"
        onExit={onHide}
        underlayClickExits={true}
        escapeExits={true}
        dialogClass="contact-us"
        verticallyCenter={true}
        >
        <div className='mw-modal'>
            <div id='contact-us-body' className="modal-body">
                <h5>Would you like to add an event or festival?&thinsp;<Link to='/addevents' onClick={onHide}>click here</Link></h5>
                <br/>
                <h5>If you&apos;re left out, misrepresented,</h5>
                <h5>found a bug, heard a rumour, whatever.</h5>
                <br/>
                <div className="centered">
                    <h4>Let us know:</h4>
                    <a id='contact-link'
                        href="mailto:contact@craftottawa.ca"
                        rel="author"
                        alt="email link for contact@craftottawa.ca">
                        contact@craftottawa.ca
                    </a>
                </div>
            </div>
            <div id='back-button'>
                <button className="btn btn-secondary" onClick={onHide}>Back</button>
            </div>
            <div id='contact-us-footer' className='modal-footer'>
                Icons made by&thinsp;
                <a className='attribution-link' href="https://www.flaticon.com/authors/freepik" target="_blank" rel='external noopener noreferrer' title="Freepik">Freepik</a>,&thinsp;
                <a className='attribution-link' href="https://www.flaticon.com/authors/mavadee" target="_blank" rel='external noopener noreferrer' title="mavadee">mavadee</a>,&thinsp;
                <a className='attribution-link' href="https://www.flaticon.com/authors/monkik" target="_blank" rel='external noopener noreferrer' title="monkik">monkik</a>,&thinsp;and&thinsp;
                <a className='attribution-link' href="https://www.flaticon.com/authors/flat-icons" target="_blank" rel='external noopener noreferrer' title="Flat Icons">Flat Icons</a>
                &thinsp;from&thinsp;
                <a className='attribution-link' href="https://www.flaticon.com/" target="_blank" rel='external noopener noreferrer' title="Flaticon">www.flaticon.com</a>
            </div>
        </div>
    </AriaModal>
)

ContactUs.propTypes = {
    onHide: PropTypes.func,
    popupDetails: PropTypes.object,
};

export default ContactUs;