import React, {Component, Fragment} from 'react';
import {Link} from "@reach/router";
import LocateControl from './LocateControl';
import EventPopup from './EventPopup.js';
import CyclingKeyPopup from './CyclingKeyPopup.js';
import L from 'leaflet';
import {Map, TileLayer, Marker, Tooltip, AttributionControl, LayersControl, LayerGroup, Popup} from 'react-leaflet';
import 'leaflet-search';
import Control from 'react-leaflet-control';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';
import DOMPurify from 'dompurify';
import {PropTypes} from 'prop-types';
import Swal from 'sweetalert2';
import $ from 'jquery';
import areEqual from "fbjs/lib/areEqual";

/* CSS: */
import 'leaflet/dist/leaflet.css';
import 'leaflet-search/src/leaflet-search.css';

//InfoBox Icons
import InstagramIcon from './images/instagram.svg';
import TwitterIcon from './images/twitter.svg';
import EventIcon from './images/pin_event.svg';
import MapsIcon from './images/maps.svg';
import CalendarIcon from './images/calendar.svg';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

L.Control.include({
    _refocusOnMap: L.Util.falseFn // Do nothing.
});

const {BaseLayer, Overlay} = LayersControl;
const uid = new ShortUniqueId();

//Location Pins
const BreweryPin = L.icon({iconUrl: require('./images/pin_brewery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const CideryPin = L.icon({iconUrl: require('./images/pin_cidery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const DistilleryPin = L.icon({iconUrl: require('./images/pin_distillery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const MeaderyPin = L.icon({iconUrl: require('./images/pin_meadery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const StorePin = L.icon({iconUrl: require('./images/pin_store.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const WineryPin = L.icon({iconUrl: require('./images/pin_winery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const EventPin = L.icon({iconUrl: require('./images/pin_event.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const DualDistilleryMeaderyPin = L.icon({iconUrl: require('./images/pin_dual_distillery_meadery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});
const DualCideryWineryPin = L.icon({iconUrl: require('./images/pin_dual_cidery_winery.svg'), iconSize: [30, 30], iconAnchor: [15, 30], popupAnchor: [0, -20]});

//Event Pins
const StarBreweryPin = L.icon({iconUrl: require('./images/pin_brewery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
const StarCideryPin = L.icon({iconUrl: require('./images/pin_cidery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
const StarDistilleryPin = L.icon({iconUrl: require('./images/pin_distillery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
const StarMeaderyPin = L.icon({iconUrl: require('./images/pin_meadery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
const StarStorePin = L.icon({iconUrl: require('./images/pin_store_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
const StarWineryPin = L.icon({iconUrl: require('./images/pin_winery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
//const StarDualDistilleryMeaderyPin = L.icon({iconUrl: require('./images/pin_dual_distillery_meadery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});
//const StarDualCideryWineryPin = L.icon({iconUrl: require('./images/pin_dual_cidery_winery_star.svg'), iconSize: [40, 40], iconAnchor: [20, 40], popupAnchor: [0, -23]});

//Other Pins
const searchSpot = L.icon({iconUrl: require('./images/search-bar_1x1.svg'), iconSize: [1, 1], iconAnchor: [1, 1]});

const crafters = [
    {id: '0', title: '1000 Islands Brewing Co', locationCode: '10IBCO', type: 'brewery', est: 'July 2019', town: 'Brockville', province: 'Ontario', home: 'https://1000islandsbrewery.ca', homeDisplay: '1000islandsbrewery.ca', maplink: 'https://goo.gl/maps/ss4cLHDMJ6XYVKvf7', twitter: 'https://twitter.com/search?q=%221000%20Islands%20Brewing%22&src=typed_query', instagram: 'https://www.instagram.com/1000islandsbrewing/', lat: 44.589037, lng:-75.684551, icon: BreweryPin, markerType: 'crafter'},
    {id: '1', title: '3 Brewers Kanata', locationCode: '3BKA', type: 'brewery', est: '15 August 2013', town: 'Kanata', province: 'Ontario', home: 'https://www.les3brasseurs.ca/en/find-a-3-brasseurs/kanata', homeDisplay: 'les3brasseurs.ca/en/find-a-3-brasseurs/kanata', maplink: 'https://g.page/3-brewers-kanata?share', twitter: 'https://twitter.com/3BrasseursCA', instagram: 'https://www.instagram.com/3brasseursca/', lat: 45.310077, lng:-75.908255, icon: BreweryPin, markerType: 'crafter'},
    {id: '2', title: '3 Brewers Sparks', locationCode: '3BSS', type: 'brewery', est: '8 January 2013', town: 'Ottawa', province: 'Ontario', home: 'https://www.les3brasseurs.ca/en/find-a-3-brasseurs/sparks', homeDisplay: 'les3brasseurs.ca/en/find-a-3-brasseurs/sparks', maplink: 'https://goo.gl/maps/By2JYokzTaW1o6wDA', twitter: 'https://twitter.com/3BrasseursCA', instagram: 'https://www.instagram.com/3brewerssparks/', lat: 45.420914, lng:-75.70148, icon: BreweryPin, markerType: 'crafter'},
    {id: '3', title: '4 Degrees Brewing Co', locationCode: '4DBG', type: 'brewery', est: '6 November 2017', town: 'Smiths Falls', province: 'Ontario', home: 'https://4degreesbrewing.com', homeDisplay: '4degreesbrewing.com', maplink: 'https://g.page/FourDegrees?share', twitter: 'https://twitter.com/4DegreesBrewery', instagram: 'https://www.instagram.com/4degreesbrewing/', lat: 44.882642, lng:-76.015275, icon: BreweryPin, markerType: 'crafter'},
    {id: '4', title: '5e Baron', locationCode: 'FEB', type: 'brewery', est: '2019', town: 'Aylmer', province: 'Quebec', home: 'https://www.facebook.com/5ebaron/', homeDisplay: 'facebook.com/5ebaron', maplink: 'https://goo.gl/maps/czGa1szsc38BfR7Y7', twitter: 'https://twitter.com/search?q=5e%20baron', instagram: 'https://www.instagram.com/5ebaron/', lat: 45.394952, lng:-75.849127, icon: BreweryPin, markerType: 'crafter'},
    {id: '5', title: 'À La Dérive Brasserie Artisanale', locationCode: 'ALD', type: 'brewery', est: '8 December 2019', town: 'Gatineau', province: 'Quebec', home: 'https://aladerivebrasserieartisanale.ca/', homeDisplay: 'aladerivebrasserieartisanale.ca', maplink: 'https://goo.gl/maps/d6neYhP9rBg8Kqzs7', twitter: 'https://twitter.com/search?q=a%20La%20Derive%20brasserie', instagram: 'https://www.instagram.com/aladerive_brasserie/', lat: 45.456297, lng:-75.691608, icon: BreweryPin, markerType: 'crafter'},
    {id: '6', title: 'ARBRU Premium Beer', locationCode: 'ARBRU', type: 'brewery', est: '17 October 2019', town: 'Mallorytown', province: 'Ontario', home: 'https://www.arbrubeer.com/', homeDisplay: 'arbrubeer.com', maplink: 'https://goo.gl/maps/DW5eYxeBq2tJv4Ju7', twitter: 'https://twitter.com/search?q=ARBRU%20Beer', instagram: 'https://www.instagram.com/arbrupremiumbeer', lat: 44.4794, lng:-75.889489, icon: BreweryPin, markerType: 'crafter'},
    {id: '7', title: 'Artist in Residence Distillerie', locationCode: 'AINRED', type: 'distillery', est: '3 April 2018', town: 'Gatineau', province: 'Quebec', home: 'https://airdistillerie.com', homeDisplay: 'airdistillerie.com', maplink: 'https://g.page/AIR-distillerie?share', twitter: 'https://twitter.com/AiRDistillerie', instagram: 'https://www.instagram.com/airdistillerie/', lat: 45.515615, lng:-75.564159, icon: DistilleryPin, markerType: 'crafter'},
    {id: '8', title: 'Ashton Brewing', locationCode: 'ABRE', type: 'brewery', est: '2011', town: 'Ashton', province: 'Ontario', home: 'https://www.ashtonbrewpub.ca', homeDisplay: 'ashtonbrewpub.ca', maplink: 'https://goo.gl/maps/NcYQ6XVc6CGesMGz5', twitter: 'https://twitter.com/AshtonBrew', instagram: 'https://www.instagram.com/ashtonbrew/', lat: 45.156678, lng:-76.03344, icon: BreweryPin, markerType: 'crafter'},
    {id: '9', title: 'BDT: Les Brasseurs du Temps', locationCode: 'BLBDT', type: 'brewery', est: 'September 2011', town: 'Gatineau', province: 'Quebec', home: 'https://www.brasseursdutemps.com', homeDisplay: 'brasseursdutemps.com', maplink: 'https://goo.gl/maps/dA7FcXszWQhzmYCx7', twitter: 'https://twitter.com/BrasseursDT_eng', instagram: 'https://www.instagram.com/brasseursdt/', lat: 45.429782, lng:-75.72683, icon: BreweryPin, markerType: 'crafter'},
    {id: '10', title: 'Beau\'s All Natural Brewing Co.', locationCode: 'BALNB', type: 'brewery', est: '2006', town: 'Vankleek Hill', province: 'Ontario', home: 'https://beaus.ca', homeDisplay: 'beaus.ca', maplink: 'https://goo.gl/maps/MWTx7BZL69Xjqz498', twitter: 'https://twitter.com/beausallnatural', instagram: 'https://www.instagram.com/beausallnatural/', lat: 45.516905, lng:-74.637245, icon: BreweryPin, markerType: 'crafter'},
    {id: '11', title: 'Beyond the Pale Brewing Co.', locationCode: 'BTPBC', type: 'brewery', est: 'November 2012', town: 'Ottawa', province: 'Ontario', home: 'https://btpshop.ca', homeDisplay: 'btpshop.ca', maplink: 'https://g.page/btpbrewing?share', twitter: 'https://twitter.com/BTPBrewing', instagram: 'https://www.instagram.com/btpbrewing/', lat: 45.407765, lng:-75.718493, icon: BreweryPin, markerType: 'crafter'},
    {id: '12', title: 'Bicycle Craft Brewery', locationCode: 'BICB', type: 'brewery', est: '26 September 2014', town: 'Ottawa', province: 'Ontario', home: 'https://bicyclecraftbrewery.ca', homeDisplay: 'bicyclecraftbrewery.ca', maplink: 'https://goo.gl/maps/WkG9xMTFBUp1qx3fA', twitter: 'https://twitter.com/BicycleBrewery', instagram: 'https://www.instagram.com/bicyclecraftbrewery/', lat: 45.406592, lng:-75.632279, icon: BreweryPin, markerType: 'crafter'},
    {id: '13', title: 'Big Rig Brewery', locationCode: 'BIGRB', type: 'brewery', est: '6 October 2014', town: 'Kanata', province: 'Ontario', home: 'https://bigrigbrew.com/brewery/', homeDisplay: 'bigrigbrew.com', maplink: 'https://goo.gl/maps/V57bJW6F1kTYhT627', twitter: 'https://twitter.com/BigRigBrewery', instagram: 'https://www.instagram.com/bigrigbrewery/', lat: 45.339623, lng:-75.901186, icon: BreweryPin, markerType: 'crafter'},
    {id: '14', title: 'Big Rig Kitchen & Brewery - Iris', locationCode: 'BIGRB-IR', type: 'brewery', est: '2012', town: 'Ottawa', province: 'Ontario', home: 'https://bigrigbrew.com/restaurant/locations/ottawa-iris/', homeDisplay: 'bigrigbrew.com/ottawa-iris', maplink: 'https://goo.gl/maps/TCRbc7UFgAYyb8uZ7', twitter: 'https://twitter.com/bigrigbrew', instagram: 'https://www.instagram.com/bigrigkitchenandbrew/', lat: 45.347523, lng:-75.78722, icon: BreweryPin, markerType: 'crafter'},
    {id: '15', title: 'Big Rig Kitchen & Brewery - Ogilvie', locationCode: 'BIGRB-GR', type: 'brewery', est: '30 March 2015', town: 'Gloucester', province: 'Ontario', home: 'https://bigrigbrew.com/restaurant/locations/ottawa-ogilvie/', homeDisplay: 'bigrigbrew.com/ottawa-ogilvie', maplink: 'https://goo.gl/maps/kBCvszLCjUxZXQ2c9', twitter: 'https://twitter.com/bigrigbrew', instagram: 'https://www.instagram.com/bigrigkitchenandbrew/', lat: 45.432496, lng:-75.610045, icon: BreweryPin, markerType: 'crafter'},
    {id: '16', title: 'Boutique Broue Ha Ha', locationCode: 'BBHHA', type: 'store', est: 'May 2010', town: 'Gatineau', province: 'Quebec', home: 'https://brouehaha.com', homeDisplay: 'brouehaha.com', maplink: 'https://goo.gl/maps/bVctZf9RJCjP3Cy8A', twitter: 'https://twitter.com/broue_ha_ha', instagram: 'https://www.instagram.com/broue_ha_ha/', lat: 45.489852, lng:-75.688183, icon: StorePin, markerType: 'crafter'},
    {id: '17', title: 'Brasserie Artisanale Gallicus', locationCode: 'BARGA', type: 'brewery', est: 'February 2019', town: 'Gatineau', province: 'Quebec', home: 'https://www.facebook.com/bieresgallicus', homeDisplay: 'facebook.com/bieresgallicus', maplink: 'https://g.page/bieresgallicus?share', twitter: 'https://twitter.com/search?q=Brasserie%20Artisanale%20Gallicus', instagram: 'https://www.instagram.com/brasserie_gallicus/', lat: 45.443202, lng:-75.813173, icon: BreweryPin, markerType: 'crafter'},
    {id: '18', title: 'Brasserie du Bas-Canada', locationCode: 'BRDUB', type: 'brewery', est: 'November 2017', town: 'Gatineau', province: 'Quebec', home: 'https://brasseriebascanada.com', homeDisplay: 'brasseriebascanada.com', maplink: 'https://goo.gl/maps/x2KJMCi5LGxkBXseA', twitter: 'https://twitter.com/bbascanada', instagram: 'https://www.instagram.com/brasseriebascanada/', lat: 45.478935, lng:-75.699766, icon: BreweryPin, markerType: 'crafter'},
    {id: '19', title: 'Brasserie Tuque de Broue', locationCode: 'BTDBE', type: 'brewery', est: 'May 2015', town: 'Embrun', province: 'Ontario', home: 'http://www.tuquedebroue.ca/en/', homeDisplay: 'tuquedebroue.ca', maplink: 'https://goo.gl/maps/FS8wzw6KzJ2nHfcf7', twitter: 'https://twitter.com/tuquedebroue', instagram: 'https://www.instagram.com/tuquedebroue/', lat: 45.269644, lng:-75.304699, icon: BreweryPin, markerType: 'crafter'},
    {id: '20', title: 'Braumeister Brewing', locationCode: 'BRBR', type: 'brewery', est: '25 August 2018', town: 'Carleton Place', province: 'Ontario', home: 'http://www.braumeister.ca', homeDisplay: 'braumeister.ca', maplink: 'https://g.page/braumeister?share', twitter: 'https://twitter.com/BraumeisterBeer', instagram: 'https://www.instagram.com/braumeisterbeer/', lat: 45.136899, lng:-76.140808, icon: BreweryPin, markerType: 'crafter'},
    {id: '21', title: 'Brauwerk Hoffman', locationCode: 'BHOF', type: 'brewery', est: '7 December 2018', town: 'Campbell\'s Bay', province: 'Quebec', home: 'http://www.brauwerk-hoffman.ca', homeDisplay: 'brauwerk-hoffman.ca', maplink: 'https://goo.gl/maps/YkhrK7FhdzvAVg7VA', twitter: 'https://twitter.com/search?q=Brauwerk%20Hoffman', instagram: 'https://www.instagram.com/brauwerkhoffman/', lat: 45.725706, lng:-76.596081, icon: BreweryPin, markerType: 'crafter'},
    {id: '22', title: 'Brew Revolution', locationCode: 'BREV', type: 'brewery', est: '24 August 2019', town: 'Stittsville', province: 'Ontario', home: 'https://brewrevolution.ca', homeDisplay: 'brewrevolution.ca', maplink: 'https://goo.gl/maps/8ZDujh3LzKe6re5SA', twitter: 'https://twitter.com/BrewRevOtt', instagram: 'https://www.instagram.com/brewrevott/', lat: 45.273044, lng:-75.931993, icon: BreweryPin, markerType: 'crafter'},
    {id: '23', title: 'Broadhead Brewing', locationCode: 'BHBR', type: 'brewery', est: '2011', town: 'Orléans', province: 'Ontario', home: 'http://broadheadbeer.com', homeDisplay: 'broadheadbeer.com', maplink: 'https://g.page/BroadheadBeer?share', twitter: 'https://twitter.com/BroadheadBeer', instagram: 'https://www.instagram.com/broadheadbeer/', lat: 45.489666, lng:-75.484347, icon: BreweryPin, markerType: 'crafter'},
    {id: '24', title: 'Broken Stick Brewing Company', locationCode: 'BSBC', type: 'brewery', est: '2014', town: 'Rockland', province: 'Ontario', home: 'https://www.brokenstickbrewing.com', homeDisplay: 'brokenstickbrewing.com', maplink: 'https://goo.gl/maps/EQDGQKCMhU7yJWq88', twitter: 'https://twitter.com/BrokenStickBrew', instagram: 'https://www.instagram.com/brokenstickbrewco/', lat: 45.46361, lng:-75.269859, icon: BreweryPin, markerType: 'crafter'},
    {id: '25', title: 'Brown\'s Independent Grocer', locationCode: 'BINGR', type: 'store', est: '15 December 2015', town: 'Stittsville', province: 'Ontario', home: 'https://www.instagram.com/beeratbrowns/', homeDisplay: 'instagram.com/beeratbrowns', maplink: 'https://goo.gl/maps/mbWAgSC2V4pLYgw67', twitter: 'https://twitter.com/beeratbrowns', instagram: 'https://www.instagram.com/beeratbrowns/', lat: 45.269014, lng:-75.928555, icon: StorePin, markerType: 'crafter'},
    {id: '26', title: 'BUSL Cider', locationCode: 'BUSL', type: 'cidery', est: '15 June 2019', town: 'Mallorytown', province: 'Ontario', home: 'https://buslcider.com/', homeDisplay: 'buslcider.com', maplink: 'https://goo.gl/maps/XPGBqcfrNv297r66A', twitter: 'https://twitter.com/search?q=busl%20cider', instagram: 'https://www.instagram.com/buslcider/', lat: 44.478353, lng:-75.892171, icon: CideryPin, markerType: 'crafter'},
    {id: '27', title: 'Calabogie Brewing', locationCode: 'CBR', type: 'brewery', est: 'July 2015', town: 'Calabogie', province: 'Ontario', home: 'https://calabogiebrewing.com/calabogie', homeDisplay: 'calabogiebrewing.com/calabogie', maplink: 'https://goo.gl/maps/PfPEPBVV3PQvhS577', twitter: 'https://twitter.com/CalabogieBrewCo', instagram: 'https://www.instagram.com/calabogiebrewingco/', lat: 45.300062, lng:-76.721955, icon: BreweryPin, markerType: 'crafter'},
    {id: '28', title: 'Calabogie Brewing - Kanata', locationCode: 'CBR-K', type: 'brewery', est: 'Fall 2018', town: 'Kanata', province: 'Ontario', home: 'https://calabogiebrewing.com/kanata', homeDisplay: 'calabogiebrewing.com/kanata', maplink: 'https://goo.gl/maps/pyvoiuzLH86svJkT6', twitter: 'https://twitter.com/CalabogieBrewCo', instagram: 'https://www.instagram.com/calabogiebrewingco/', lat: 45.341239, lng:-75.902834, icon: BreweryPin, markerType: 'crafter'},
    {id: '29', title: 'Cartwright Springs', locationCode: 'CSPR', type: 'brewery', est: '8 July 2015', town: 'Pakenham', province: 'Ontario', home: 'http://csbeer.ca', homeDisplay: 'csbeer.ca', maplink: 'https://goo.gl/maps/EZHYDcthWeeaMypS7', twitter: 'https://twitter.com/CSBbeer', instagram: 'https://www.instagram.com/cartwrightsprings/', lat: 45.347548, lng:-76.344609, icon: BreweryPin, markerType: 'crafter'},
    {id: '30', title: 'Cassel Brewery', locationCode: 'CLBR', type: 'brewery', est: 'July 2012', town: 'Casselman', province: 'Ontario', home: 'https://casselbrewery.ca', homeDisplay: 'casselbrewery.ca', maplink: 'https://goo.gl/maps/LuUxV6gT64EMRdUZA', twitter: 'https://twitter.com/CasselBrewery', instagram: 'https://www.instagram.com/casselbrewery/', lat: 45.306002, lng:-75.080937, icon: BreweryPin, markerType: 'crafter'},
    {id: '31', title: 'Clocktower Brew Pub - Elgin', locationCode: 'CBP-EL', type: 'brewery', est: '14 October 2015', town: 'Ottawa', province: 'Ontario', home: 'https://www.clocktower.ca/locations/elgin-street', homeDisplay: 'clocktower.ca/locations/elgin-street', maplink: 'https://goo.gl/maps/4C39aANZzMfAaCrK6', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.419379, lng:-75.691891, icon: BreweryPin, markerType: 'crafter'},
    {id: '32', title: 'Clocktower Brew Pub - Glebe', locationCode: 'CBP-GL', type: 'brewery', est: '1 February 1997', town: 'Ottawa', province: 'Ontario', home: 'https://www.clocktower.ca/locations/glebe-brew-house', homeDisplay: 'clocktower.ca/locations/glebe-brew-house', maplink: 'https://goo.gl/maps/CFuHz25MUsAvW4Eo9', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.408362, lng:-75.691138, icon: BreweryPin, markerType: 'crafter'},
    {id: '33', title: 'Clocktower Brew Pub - New Edinburgh', locationCode: 'CBP-NE', type: 'brewery', est: '22 May 2015', town: 'Ottawa', province: 'Ontario', home: 'https://www.clocktower.ca/locations/new-edinburgh', homeDisplay: 'clocktower.ca/locations/new-edinburgh', maplink: 'https://goo.gl/maps/u327cvAhq4jPFvaA9', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.439681, lng:-75.677167, icon: BreweryPin, markerType: 'crafter'},
    {id: '34', title: 'Clocktower Brew Pub - Orleans', locationCode: 'CBP-OR', type: 'brewery', est: '10 May 2017', town: 'Orléans', province: 'Ontario', home: 'https://www.clocktower.ca/locations/orleans', homeDisplay: 'clocktower.ca/locations/orleans', maplink: 'https://goo.gl/maps/s5Yik7XGewt5feVD9', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.468694, lng:-75.454125, icon: BreweryPin, markerType: 'crafter'},
    {id: '35', title: 'Clocktower Brew Pub - Rideau', locationCode: 'CBP-RI', type: 'brewery', est: '21 November 2018', town: 'Ottawa', province: 'Ontario', home: 'https://www.clocktower.ca/locations/rideau', homeDisplay: 'clocktower.ca/locations/rideau', maplink: 'https://goo.gl/maps/FSZ5VZsc2YNHiT51A', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.426624, lng:-75.690116, icon: BreweryPin, markerType: 'crafter'},
    {id: '36', title: 'Clocktower Brew Pub - Westboro', locationCode: 'CBP-WE', type: 'brewery', est: '9 December 2011', town: 'Ottawa', province: 'Ontario', home: 'https://www.clocktower.ca/locations/westboro', homeDisplay: 'clocktower.ca/locations/westboro', maplink: 'https://goo.gl/maps/HPxwGHwLRJsthb7G7', twitter: 'https://twitter.com/The_Clocktower', instagram: 'https://www.instagram.com/clocktowerbrewpub/', lat: 45.390026, lng:-75.757537, icon: BreweryPin, markerType: 'crafter'},
    {id: '37', title: 'Conspiracy Theory Brewing', locationCode: 'CTBG', type: 'brewery', est: '2017', town: 'Bells Corners', province: 'Ontario', home: 'https://ctbrewing.ca', homeDisplay: 'ctbrewing.ca', maplink: 'https://goo.gl/maps/gjUZYKhtKKw1hptE7', twitter: 'https://twitter.com/theory_brew', instagram: 'https://www.instagram.com/conspiracytheorybrewing/', lat: 45.319061, lng:-75.83919, icon: BreweryPin, markerType: 'crafter'},
    {id: '38', title: 'Coronation Hall Cider Mills', locationCode: 'CHCM', type: 'cidery', est: '11 October 2008', town: 'Bristol', province: 'Quebec', home: 'http://www.coronationhall.com', homeDisplay: 'coronationhall.com', maplink: 'https://goo.gl/maps/tUSoFTYqzJSCUvd99', twitter: 'https://twitter.com/search?q=Coronation%20Hall%20Cider%20Mills', instagram: 'https://www.instagram.com/explore/locations/802771363260044/coronation-hall-cider-mill/', lat: 45.527909, lng:-76.45506, icon: CideryPin, markerType: 'crafter'},
    {id: '39', title: 'Covered Bridge Brewing', locationCode: 'CBB', type: 'brewery', est: '2013', town: 'Stittsville', province: 'Ontario', home: 'https://www.coveredbridgebrewing.com', homeDisplay: 'coveredbridgebrewing.com', maplink: 'https://goo.gl/maps/K4zG1TAeKwnfFgzP6', twitter: 'https://twitter.com/cbbeer', instagram: 'https://www.instagram.com/coveredbridgebrewing/', lat: 45.277323, lng:-75.903748, icon: BreweryPin, markerType: 'crafter'},
    {id: '40', title: 'Crooked Mile Brewing', locationCode: 'CMB', type: 'brewery', est: '23 October 2016', town: 'Almonte', province: 'Ontario', home: 'http://crookedmile.ca', homeDisplay: 'crookedmile.ca', maplink: 'https://goo.gl/maps/NuEe3aDrC7DHhjH9A', twitter: 'https://twitter.com/CrookedMileBeer', instagram: 'https://www.instagram.com/crookedmilebrewing/', lat: 45.235167, lng:-76.18079, icon: BreweryPin, markerType: 'crafter'},
    {id: '41', title: 'Dairy Distillery', locationCode: 'VDIS', type: 'distillery', est: '1 November 2018', town: 'Almonte', province: 'Ontario', home: 'https://dairydistillery.com', homeDisplay: 'dairydistillery.com', maplink: 'https://g.page/dairydistillery?share', twitter: 'https://twitter.com/dairydistillery', instagram: 'https://www.instagram.com/dairydistillery/', lat: 45.232668, lng:-76.182245, icon: DistilleryPin, markerType: 'crafter'},
    {id: '42', title: 'Dépanneur Rapido', locationCode: 'DEPR', type: 'store', est: 'the 80s', town: 'Aylmer', province: 'Quebec', home: 'http://www.depanneurrapido.com/', homeDisplay: 'depanneurrapido.com', maplink: 'https://goo.gl/maps/47arHXzKj13wLLeR8', twitter: 'https://twitter.com/search?q=%22D%C3%A9panneur%20Rapido%22', instagram: 'https://www.instagram.com/explore/locations/285860023/depanneur-rapido/', lat: 45.397913, lng:-75.855212, icon: StorePin, markerType: 'crafter'},
    {id: '43', title: 'Distillerie 3 Lacs', locationCode: 'D3LS', type: 'distillery', est: '29 August 2019', town: 'Salaberry-de-Valleyfield', province: 'Quebec', home: 'https://distillerietroislacs.ca', homeDisplay: 'distillerietroislacs.ca', maplink: 'https://g.page/Distillerie3Lacs?share', twitter: 'https://twitter.com/search?q=Distillerie%203%20Lacs', instagram: 'https://www.instagram.com/distillerie3lacs/', lat: 45.269394, lng:-74.146539, icon: DistilleryPin, markerType: 'crafter'},
    {id: '44', title: 'Domaine Cléroux', locationCode: 'DMCL', type: 'cidery', est: '2010', town: 'Casselman', province: 'Ontario', home: 'http://www.domainecleroux.ca/', homeDisplay: 'domainecleroux.ca', maplink: 'https://goo.gl/maps/4Mp85whBA89GLjyE6', twitter: 'https://twitter.com/search?q=domaine%20cleroux', instagram: 'https://www.instagram.com/domainecleroux/', lat: 45.36412, lng:-75.068301, icon: DualCideryWineryPin, markerType: 'crafter'},
    {id: '45', title: 'Domaine Cléroux', locationCode: 'DMCL', type: 'winery', est: '2010', town: 'Casselman', province: 'Ontario', home: 'http://www.domainecleroux.ca/', homeDisplay: 'domainecleroux.ca', maplink: 'https://goo.gl/maps/4Mp85whBA89GLjyE6', twitter: 'https://twitter.com/search?q=domaine%20cleroux', instagram: 'https://www.instagram.com/domainecleroux/', lat: 45.36412, lng:-75.068301, icon: DualCideryWineryPin, markerType: 'crafter'},
    {id: '46', title: 'Domaine de Pontiac Village', locationCode: 'DDPV', type: 'winery', est: '2007', town: 'Pontiac', province: 'Quebec', home: 'https://www.domainepontiac.com', homeDisplay: 'domainepontiac.com', maplink: 'https://goo.gl/maps/5dTgpVeBNPwQ6ZQT9', twitter: 'https://twitter.com/search?q=Domaine%20de%20Pontiac%20Village', instagram: 'https://www.instagram.com/vignobledomainepontiacwinery/', lat: 45.499898, lng:-76.256139, icon: WineryPin, markerType: 'crafter'},
    {id: '47', title: 'Domaine Du Mont-Vezeau', locationCode: 'DDMU', type: 'winery', est: 'January 2008', town: 'Ripon', province: 'Quebec', home: 'http://www.domainemont-vezeau.com', homeDisplay: 'domainemont-vezeau.com', maplink: 'https://goo.gl/maps/sXcUXZHXJjMNm9Cd6', twitter: 'https://twitter.com/MontVezeau', instagram: 'https://www.instagram.com/domainemontvezeau/', lat: 45.834528, lng:-75.062402, icon: WineryPin, markerType: 'crafter'},
    {id: '48', title: 'Domaine Perrault', locationCode: 'DPER', type: 'winery', est: '2005', town: 'Cumberland', province: 'Ontario', home: 'https://www.domaineperrault.ca', homeDisplay: 'domaineperrault.ca', maplink: 'https://goo.gl/maps/5c9oqJt2r4eeD3mZA', twitter: 'https://twitter.com/DomainePerrault', instagram: 'https://www.instagram.com/domaineperraultwinery/', lat: 45.386792, lng:-75.419852, icon: WineryPin, markerType: 'crafter'},
    {id: '49', title: 'Dominion City Brewing', locationCode: 'DCBR', type: 'brewery', est: '9 August 2014', town: 'Gloucester', province: 'Ontario', home: 'https://www.dominioncity.ca', homeDisplay: 'dominioncity.ca', maplink: 'https://goo.gl/maps/C5iStaNnJPy9e2LKA', twitter: 'https://twitter.com/dominioncitybc', instagram: 'https://www.instagram.com/dominioncity/', lat: 45.457224, lng:-75.585597, icon: BreweryPin, markerType: 'crafter'},
    {id: '50', title: 'Étienne Brûlé Brewery', locationCode: 'EBRB', type: 'brewery', est: '2016', town: 'Embrun', province: 'Ontario', home: 'http://etiennebrule.ca', homeDisplay: 'etiennebrule.ca', maplink: 'https://goo.gl/maps/iewLjtdURnx4b8rXA', twitter: 'https://twitter.com/BrasserieEb', instagram: 'https://www.instagram.com/brasserieetiennebrule/', lat: 45.271689, lng:-75.289355, icon: BreweryPin, markerType: 'crafter'},
    {id: '51', title: 'Evergreen Craft Ales', locationCode: 'EVCA', type: 'brewery', est: '13 September 2019', town: 'Kanata', province: 'Ontario', home: 'http://www.evergreencraftales.com', homeDisplay: 'evergreencraftales.com', maplink: 'https://goo.gl/maps/gyhySCaYMFcajfYd7', twitter: 'https://twitter.com/evergreenales', instagram: 'https://www.instagram.com/evergreencraftales/', lat: 45.301769, lng:-75.914586, icon: BreweryPin, markerType: 'crafter'},
    {id: '52', title: 'Farmgate Cider', locationCode: 'FCID', type: 'cidery', est: '1 April 2017', town: 'Arnprior', province: 'Ontario', home: 'https://farmgatecider.ca', homeDisplay: 'farmgatecider.ca', maplink: 'https://goo.gl/maps/tgQkU6qFYXJYTCJi9', twitter: 'https://twitter.com/FarmgateCider', instagram: 'https://www.instagram.com/farmgatecider/', lat: 45.413005, lng:-76.240642, icon: CideryPin, markerType: 'crafter'},
    {id: '53', title: 'Ferme Brasserie Schoune', locationCode: 'FBRS', type: 'brewery', est: '1996', town: 'Saint-Polycarpe', province: 'Quebec', home: 'https://www.schoune.com', homeDisplay: 'schoune.com', maplink: 'https://g.page/Brasserie-Schoune?share', twitter: 'https://twitter.com/brewerySchoune', instagram: 'https://www.instagram.com/fermebrasserie.schoune/', lat: 45.295619, lng:-74.339476, icon: BreweryPin, markerType: 'crafter'},
    {id: '54', title: 'Flora Hall Brewing', locationCode: 'FHBR', type: 'brewery', est: 'October 2017', town: 'Ottawa', province: 'Ontario', home: 'http://florahallbrewing.ca', homeDisplay: 'florahallbrewing.ca', maplink: 'https://g.page/florahallbrewing?share', twitter: 'https://twitter.com/florahallbeer', instagram: 'https://www.instagram.com/florahallbeer/', lat: 45.410785, lng:-75.693247, icon: BreweryPin, markerType: 'crafter'},
    {id: '55', title: 'Flying Canoe Hard Cider', locationCode: 'FCHC', type: 'cidery', est: 'Spring 2016', town: 'Spencerville', province: 'Ontario', home: 'https://www.flyingcanoehardcider.com', homeDisplay: 'flyingcanoehardcider.com', maplink: 'https://goo.gl/maps/VSZJqHzCNyPcHsDX8', twitter: 'https://twitter.com/hardflyingcanoe/', instagram: 'https://www.instagram.com/hardflyingcanoe/', lat: 44.843342, lng:-75.546195, icon: CideryPin, markerType: 'crafter'},
    {id: '56', title: 'Gainsbourg Brasserie', locationCode: 'GBRA', type: 'brewery', est: 'February 2013', town: 'Gatineau', province: 'Quebec', home: 'https://www.gainsbourg.ca', homeDisplay: 'gainsbourg.ca', maplink: 'https://goo.gl/maps/UaB9GyLFzhHNiKZV9', twitter: 'https://twitter.com/search?q=Gainsbourg%20Brasserie', instagram: 'https://www.instagram.com/explore/tags/gainsbourgbrasserie/', lat: 45.42685, lng:-75.715672, icon: BreweryPin, markerType: 'crafter'},
    {id: '57', title: 'Gan Brewing Company', locationCode: 'GBRC', type: 'brewery', est: '2011', town: 'Gananoque', province: 'Ontario', home: 'https://www.ganbeer.com', homeDisplay: 'ganbeer.com', maplink: 'https://goo.gl/maps/HmT8djPeTV3YucqA9', twitter: 'https://twitter.com/GanBrewingCo', instagram: 'https://www.instagram.com/ganbrewingco/', lat: 44.327923, lng:-76.164324, icon: BreweryPin, markerType: 'crafter'},
    {id: '58', title: 'Humble Beginnings Brewing Co.', locationCode: 'HBBC', type: 'brewery', est: '28 April 2018', town: 'Ingleside', province: 'Ontario', home: 'http://www.humblebeginningsbrewing.ca', homeDisplay: 'humblebeginningsbrewing.ca', maplink: 'https://g.page/hbbrewingco?share', twitter: 'https://twitter.com/hbbrewingco', instagram: 'https://www.instagram.com/hbbrewingco/', lat: 44.996648, lng:-74.990127, icon: BreweryPin, markerType: 'crafter'},
    {id: '59', title: 'Intermiel', locationCode: 'INTML', type: 'distillery', est: '1976', town: 'Mirabel', province: 'Quebec', home: 'https://intermiel.com', homeDisplay: 'intermiel.com', maplink: 'https://g.page/IntermielQC?share', twitter: 'https://twitter.com/hashtag/intermiel', instagram: 'https://www.instagram.com/intermiel/', lat: 45.5693, lng:-74.074573, icon: DualDistilleryMeaderyPin, markerType: 'crafter'},
    {id: '60', title: 'Intermiel', locationCode: 'INTML', type: 'meadery', est: '1976', town: 'Mirabel', province: 'Quebec', home: 'https://intermiel.com', homeDisplay: 'intermiel.com', maplink: 'https://g.page/IntermielQC?share', twitter: 'https://twitter.com/hashtag/intermiel', instagram: 'https://www.instagram.com/intermiel/', lat: 45.5693, lng:-74.074573, icon: DualDistilleryMeaderyPin, markerType: 'crafter'},
    {id: '61', title: 'Jabulani Vineyard and Winery', locationCode: 'JVAW', type: 'winery', est: '2011', town: 'Richmond', province: 'Ontario', home: 'http://www.jabulani.ca', homeDisplay: 'jabulani.ca', maplink: 'https://goo.gl/maps/WpHwZv4ZLPZQng5S8', twitter: 'https://twitter.com/jabulani_winery', instagram: 'https://www.instagram.com/jabulani_winery/', lat: 45.130885, lng:-75.916599, icon: WineryPin, markerType: 'crafter'},
    {id: '62', title: 'Kichesippi Beer Co', locationCode: 'KBCO', type: 'brewery', est: 'April 2010', town: 'Nepean', province: 'Ontario', home: 'https://www.kbeer.ca', homeDisplay: 'kbeer.ca', maplink: 'https://g.page/kichesippibeer?share', twitter: 'https://twitter.com/kichesippibeer', instagram: 'https://www.instagram.com/kichesippibeer/', lat: 45.318091, lng:-75.844186, icon: BreweryPin, markerType: 'crafter'},
    {id: '63', title: 'KIN Vineyards', locationCode: 'KVIN', type: 'winery', est: '8 July 2017', town: 'Carp', province: 'Ontario', home: 'https://kinvineyards.com', homeDisplay: 'kinvineyards.com', maplink: 'https://goo.gl/maps/VawkVt8xdiPLHyKu6', twitter: 'https://twitter.com/kinvineyards', instagram: 'https://www.instagram.com/kin_vineyards/', lat: 45.354641, lng:-76.052335, icon: WineryPin, markerType: 'crafter'},
    {id: '64', title: 'King\'s Lock Craft Distillery', locationCode: 'KILCD', type: 'distillery', est: '25 August 2016', town: 'Johnstown', province: 'Ontario', home: 'https://www.klcraftdistillery.ca', homeDisplay: 'klcraftdistillery.ca', maplink: 'https://goo.gl/maps/LnjydZWEsYfDj9oV6', twitter: 'https://twitter.com/search?q=King%27s%20Lock%20Craft%20Distillery', instagram: 'https://www.instagram.com/kingslock/', lat: 44.723493, lng:-75.488896, icon: DistilleryPin, markerType: 'crafter'},
    {id: '65', title: 'L\'Ancienne Banque', locationCode: 'LABE', type: 'brewery', est: '2014', town: 'Chapeau', province: 'Quebec', home: 'https://lanciennebanque.com/', homeDisplay: 'lanciennebanque.com', maplink: 'https://goo.gl/maps/Coi1NgBbc47CSjtPA', twitter: 'https://twitter.com/search?q=%22L%27Ancienne%20Banque%22', instagram: 'https://www.instagram.com/explore/locations/966168775/lancienne-banque/', lat: 45.911112, lng:-77.073563, icon: BreweryPin, markerType: 'crafter'},
    {id: '66', title: 'Le Vignoble du Clos Baillie', locationCode: 'LVDCB', type: 'winery', est: '1999', town: 'Gatineau', province: 'Quebec', home: 'http://www.quebecvino.com', homeDisplay: 'quebecvino.com', maplink: 'https://goo.gl/maps/RQR1TbX6mi8WVuDm8', twitter: 'https://twitter.com/ClosBaillie', instagram: 'https://www.instagram.com/clos.baillie/', lat: 45.466666, lng:-75.88932, icon: WineryPin, markerType: 'crafter'},
    {id: '67', title: 'Les Brasseurs de Montebello', locationCode: 'LBDM', type: 'brewery', est: '2013', town: 'Montebello', province: 'Quebec', home: 'http://brasseursdemontebello.com', homeDisplay: 'brasseursdemontebello.com', maplink: 'https://goo.gl/maps/XSE4pabneLZ2je578', twitter: 'https://twitter.com/BrasseursM', instagram: 'https://www.instagram.com/brasseurs_de_montebello/', lat: 45.650281, lng:-74.943889, icon: BreweryPin, markerType: 'crafter'},
    {id: '68', title: 'Little Red Wagon Winery', locationCode: 'LRWW', type: 'winery', est: '2017', town: 'Shawville', province: 'Quebec', home: 'http://www.littleredwagonwinery.com', homeDisplay: 'littleredwagonwinery.com', maplink: 'https://g.page/littleredwagonwinery?share', twitter: 'https://twitter.com/LRWwinery', instagram: 'https://www.instagram.com/lrw_winery/', lat: 45.617725, lng:-76.533953, icon: WineryPin, markerType: 'crafter'},
    {id: '69', title: 'Lowertown Brewery', locationCode: 'LOBR', type: 'brewery', est: 'June 2014', town: 'Ottawa', province: 'Ontario', home: 'http://www.lowertownbrewery.ca', homeDisplay: 'lowertownbrewery.ca', maplink: 'https://goo.gl/maps/syGMLqV6bRkTvmCy5', twitter: 'https://twitter.com/LowertownOttawa', instagram: 'https://www.instagram.com/lowertownottawa/', lat: 45.428775, lng:-75.692216, icon: BreweryPin, markerType: 'crafter'},
    {id: '70', title: 'Microbrasserie Le Castor', locationCode: 'MLCR', type: 'brewery', est: '23 June 2012', town: 'Rigaud', province: 'Quebec', home: 'https://www.microlecastor.ca', homeDisplay: 'microlecastor.ca', maplink: 'https://goo.gl/maps/brFds9FMmkbge6m66', twitter: 'https://twitter.com/search?q=Microbrasserie%20Le%20Castor', instagram: 'https://www.instagram.com/microlecastor/', lat: 45.485997, lng:-74.317987, icon: BreweryPin, markerType: 'crafter'},
    {id: '71', title: 'Microbrasserie Maltéus', locationCode: 'MIMA', type: 'brewery', est: 'June 2013', town: 'Valleyfield', province: 'Quebec', home: 'http://www.mbmalteus.com', homeDisplay: 'mbmalteus.com', maplink: 'https://goo.gl/maps/3g8kaMTVXeehgC5r8', twitter: 'https://twitter.com/search?q=Microbrasserie%20Malt%C3%A9us', instagram: 'https://www.instagram.com/explore/tags/microbrasseriemalt%C3%A9us/', lat: 45.257673, lng:-74.116746, icon: BreweryPin, markerType: 'crafter'},
    {id: '72', title: 'Microbrasserie Trois-Lacs', locationCode: 'MTRO', type: 'brewery', est: '7 December 2019', town: 'Vaudreuil-Dorion', province: 'Quebec', home: 'https://www.3lacs.com', homeDisplay: '3lacs.com', maplink: 'https://goo.gl/maps/VSqbBEkis7kvnDK59', twitter: 'https://twitter.com/search?q=Microbrasserie%20Trois-Lacs', instagram: 'https://www.instagram.com/microbrasserie3lacs/', lat: 45.404134, lng:-74.051725, icon: BreweryPin, markerType: 'crafter'},
    {id: '73', title: 'Miel Nature', locationCode: 'MNAT', type: 'meadery', est: '1980', town: 'Beauharnois', province: 'Quebec', home: 'https://www.mielnature.com', homeDisplay: 'mielnature.com', maplink: 'https://goo.gl/maps/GKz7sAMtEDXK3dWN6', twitter: 'https://twitter.com/mielnatureqc', instagram: 'https://www.instagram.com/mielnatureinc/', lat: 45.293343, lng:-73.842101, icon: MeaderyPin, markerType: 'crafter'},
    {id: '74', title: 'Mill St. Brew Pub', locationCode: 'MSBP', type: 'brewery', est: 'January 2012', town: 'Ottawa', province: 'Ontario', home: 'http://millstreetbrewery.com/ottawa-brew-pub', homeDisplay: 'millstreetbrewery.com/ottawa-brew-pub', maplink: 'https://goo.gl/maps/6NJdNPBkkHcTXW6t9', twitter: 'https://twitter.com/MillStBrewPubOT', instagram: 'https://www.instagram.com/millstbrewpubottawa/', lat: 45.419685, lng:-75.712688, icon: BreweryPin, markerType: 'crafter'},
    {id: '75', title: 'Nita Beer', locationCode: 'NBEE', type: 'brewery', est: 'April 2014', town: 'Nepean', province: 'Ontario', home: 'https://nitabeer.com', homeDisplay: 'nitabeer.com', maplink: 'https://goo.gl/maps/fuhqg3N8PGxv2CSN6', twitter: 'https://twitter.com/nitabeerco', instagram: 'https://www.instagram.com/nitabeerco/', lat: 45.342158, lng:-75.715591, icon: BreweryPin, markerType: 'crafter'},
    {id: '76', title: 'North of 7 Distillery', locationCode: 'NO7D', type: 'distillery', est: 'September 2014', town: 'Ottawa', province: 'Ontario', home: 'https://www.northof7distillery.ca', homeDisplay: 'northof7distillery.ca', maplink: 'https://goo.gl/maps/6Mg2DBzK312SnzmLA', twitter: 'https://twitter.com/N7Distillery', instagram: 'https://www.instagram.com/n7distillery/', lat: 45.405616, lng:-75.626113, icon: DistilleryPin, markerType: 'crafter'},
    {id: '77', title: 'O\'Kenny Craft Spirits', locationCode: 'OCRS', type: 'distillery', est: 'August 2019', town: 'Pembroke', province: 'Ontario', home: 'http://www.okenny.ca', homeDisplay: 'okenny.ca', maplink: 'https://goo.gl/maps/ozwo3ePC6dDCKw3t6', twitter: 'https://twitter.com/search?q=O%27Kenny%20Craft%20Spirits', instagram: 'https://www.instagram.com/okennycraftspirits/', lat: 45.799536, lng:-77.113811, icon: DistilleryPin, markerType: 'crafter'},
    {id: '78', title: 'Original Local Beers', locationCode: 'OLOB', type: 'brewery', est: '2 November 2018', town: 'Ottawa', province: 'Ontario', home: 'https://www.localbeers.ca', homeDisplay: 'localbeers.ca', maplink: 'https://goo.gl/maps/Yth5pkhj9m5ptvjB6', twitter: 'https://twitter.com/LagerLocal', instagram: 'https://www.instagram.com/local.lager/', lat: 45.395805, lng:-75.601789, icon: BreweryPin, markerType: 'crafter'},
    {id: '79', title: 'Orleans Brewing', locationCode: 'OBRE', type: 'brewery', est: '11 May 2019', town: 'Orléans', province: 'Ontario', home: 'https://www.orleansbrewing.com', homeDisplay: 'orleansbrewing.com', maplink: 'https://g.page/OrleansBrewingCo?share', twitter: 'https://twitter.com/OrleansBrewing', instagram: 'https://www.instagram.com/orleansbrewing/', lat: 45.459726, lng:-75.490046, icon: BreweryPin, markerType: 'crafter'},
    {id: '80', title: 'Overflow Brewing Company', locationCode: 'OBRC', type: 'brewery', est: 'June 2018', town: 'Ottawa', province: 'Ontario', home: 'https://overflowbeer.com', homeDisplay: 'overflowbeer.com', maplink: 'https://g.page/overflowbeer?share', twitter: 'https://twitter.com/overflowbrewing', instagram: 'https://www.instagram.com/overflowbrewingco/', lat: 45.375946, lng:-75.669286, icon: BreweryPin, markerType: 'crafter'},
    {id: '81', title: 'Perth Brewery', locationCode: 'PEBR', type: 'brewery', est: '1993', town: 'Perth', province: 'Ontario', home: 'http://www.perthbrewery.ca', homeDisplay: 'perthbrewery.ca', maplink: 'https://goo.gl/maps/BXYgzA3Aek7hBfuS8', twitter: 'https://twitter.com/PerthBrewery', instagram: 'https://www.instagram.com/perthbrewery/', lat: 44.906807, lng:-76.270171, icon: BreweryPin, markerType: 'crafter'},
    {id: '82', title: 'Ridge Rock Brewing Company', locationCode: 'RRBC', type: 'brewery', est: '2018', town: 'Carp', province: 'Ontario', home: 'https://ridgerockbrewco.ca', homeDisplay: 'ridgerockbrewco.ca', maplink: 'https://g.page/RidgeRockBrewCo?share', twitter: 'https://twitter.com/RidgeRockBrewCo', instagram: 'https://www.instagram.com/ridgerockbrewco/', lat: 45.344289, lng:-76.035243, icon: BreweryPin, markerType: 'crafter'},
    {id: '83', title: 'Rurban Brewing Co.', locationCode: 'RBRC', type: 'brewery', est: '2013', town: 'Cornwall', province: 'Ontario', home: 'http://www.rurbanbrewing.com', homeDisplay: 'rurbanbrewing.com', maplink: 'https://g.page/RurbanBrewing?share', twitter: 'https://twitter.com/RurbanBrewing', instagram: 'https://www.instagram.com/rurbanbrewing/', lat: 45.019447, lng:-74.739445, icon: BreweryPin, markerType: 'crafter'},
    {id: '84', title: 'Scheuermann Vineyard & Winery', locationCode: 'SVAW', type: 'winery', est: '28 August 2015', town: 'Westport', province: 'Ontario', home: 'https://www.scheuermannwinery.com', homeDisplay: 'scheuermannwinery.com', maplink: 'https://goo.gl/maps/1LQ3yYvJDumwSFpc6', twitter: 'https://twitter.com/ScheuermannWine', instagram: 'https://www.instagram.com/schvinewine/', lat: 44.679981, lng:-76.409418, icon: WineryPin, markerType: 'crafter'},
    {id: '85', title: 'Small Pony Barrel Works', locationCode: 'SPBW', type: 'brewery', est: '9 December 2017', town: 'Kanata', province: 'Ontario', home: 'https://www.smallponybarrelworks.com', homeDisplay: 'smallponybarrelworks.com', maplink: 'https://goo.gl/maps/YYgRTLiMp1c4CE3h8', twitter: 'https://twitter.com/smallponybeer', instagram: 'https://www.instagram.com/smallponybarrelworks/', lat: 45.339502, lng:-75.902168, icon: BreweryPin, markerType: 'crafter'},
    {id: '86', title: 'Smokie Ridge Vineyard', locationCode: 'SRVD', type: 'winery', est: '2007', town: 'North Dundas', province: 'Ontario', home: 'http://www.smokiesgrapes.com', homeDisplay: 'smokiesgrapes.com', maplink: 'https://goo.gl/maps/hYU8uZHshHcbDnwZ6', twitter: 'https://twitter.com/SmokiesVine', instagram: 'https://www.instagram.com/smokieridge/', lat: 44.995178, lng:-75.512993, icon: WineryPin, markerType: 'crafter'},
    {id: '87', title: 'Spark Beer', locationCode: 'SBEE', type: 'brewery', est: '8 January 2020', town: 'Ottawa', province: 'Ontario', home: 'https://spark.beer', homeDisplay: 'spark.beer', maplink: 'https://g.page/sparkbeer?share', twitter: 'https://twitter.com/SparkBeer', instagram: 'https://www.instagram.com/spark.beer/', lat: 45.410779, lng:-75.707063, icon: BreweryPin, markerType: 'crafter'},
    {id: '88', title: 'Square Timber Brewing', locationCode: 'SQTB', type: 'brewery', est: '10 September 2014', town: 'Pembroke', province: 'Ontario', home: 'http://www.squaretimber.com', homeDisplay: 'squaretimber.com', maplink: 'https://goo.gl/maps/Rtr1j7VLByRriELe9', twitter: 'https://twitter.com/squaretimber', instagram: 'https://www.instagram.com/squaretimber/', lat: 45.706099, lng:-77.141536, icon: BreweryPin, markerType: 'crafter'},
    {id: '89', title: 'Stalwart Brewing Co', locationCode: 'SBCO', type: 'brewery', est: '1 December 2015', town: 'Carleton Place', province: 'Ontario', home: 'http://stalwartbrewing.ca', homeDisplay: 'stalwartbrewing.ca', maplink: 'https://goo.gl/maps/2fAr6fyEnJff3iSg9', twitter: 'https://twitter.com/StalwartBrewing', instagram: 'https://www.instagram.com/stalwartbrewing/', lat: 45.141324, lng:-76.148621, icon: BreweryPin, markerType: 'crafter'},
    {id: '90', title: 'Stone Crop Acres Winery and Vineyard', locationCode: 'SCAWV', type: 'winery', est: '15 July 2017', town: 'Morrisburg', province: 'Ontario', home: 'https://www.stonecropacres.ca', homeDisplay: 'stonecropacres.ca', maplink: 'https://goo.gl/maps/p8uNK1sJHKQbig4u6', twitter: 'https://twitter.com/hashtag/Stonecropacres', instagram: 'https://www.instagram.com/stonecropacres/', lat: 44.925539, lng:-75.199133, icon: WineryPin, markerType: 'crafter'},
    {id: '91', title: 'Stonehouse Vineyard', locationCode: 'SHV', type: 'winery', est: '19 December 2018', town: 'Locheil', province: 'Ontario', home: 'https://www.stonehousevineyard.ca/', homeDisplay: 'stonehousevineyard.ca', maplink: 'https://g.page/stonehousevineyard1793?share', twitter: 'https://twitter.com/StonehouseVine1', instagram: 'https://www.instagram.com/stonehousevineyard1793/', lat: 45.379431, lng:-74.619415, icon: WineryPin, markerType: 'crafter'},
    {id: '92', title: 'Stray Dog Brewing', locationCode: 'SDBR', type: 'brewery', est: 'July 2017', town: 'Orléans', province: 'Ontario', home: 'https://stray-dog-brewing.myshopify.com', homeDisplay: 'stray-dog-brewing.myshopify.com', maplink: 'https://g.page/StrayDogBrewing?share', twitter: 'https://twitter.com/StrayDogBeer', instagram: 'https://www.instagram.com/straydogbrewingco/', lat: 45.491205, lng:-75.482458, icon: BreweryPin, markerType: 'crafter'},
    {id: '93', title: 'Tooth and Nail Brewing Company', locationCode: 'TANBC', type: 'brewery', est: '2015', town: 'Ottawa', province: 'Ontario', home: 'https://toothandnailbeer.com', homeDisplay: 'toothandnailbeer.com', maplink: 'https://goo.gl/maps/jhAcL9mPq7Jq7c9M9', twitter: 'https://twitter.com/toothnailbeer', instagram: 'https://www.instagram.com/toothandnailbeer/', lat: 45.405456, lng:-75.722598, icon: BreweryPin, markerType: 'crafter'},
    {id: '94', title: 'Top Shelf Distillers', locationCode: 'TSDS', type: 'distillery', est: 'mid-December 2015', town: 'Perth', province: 'Ontario', home: 'https://topshelfdistillers.com', homeDisplay: 'topshelfdistillers.com', maplink: 'https://goo.gl/maps/ij86h1KaNo9dVhdA7', twitter: 'https://twitter.com/TSDistillers', instagram: 'https://www.instagram.com/tsdistillers/', lat: 44.884041, lng:-76.24013, icon: DistilleryPin, markerType: 'crafter'},
    {id: '95', title: 'Two Hawks Brewing Co.', locationCode: 'THBC', type: 'brewery', est: 'September 2018', town: 'Pembroke', province: 'Ontario', home: 'https://twohawksbrewingco.ca', homeDisplay: 'twohawksbrewingco.ca', maplink: 'https://goo.gl/maps/wGLWSbbR3pkzwmnY8', twitter: 'https://twitter.com/search?q=%22Two%20Hawks%20Brewing%22', instagram: 'https://www.instagram.com/twohawksbrewingco/', lat: 45.842699, lng:-77.163224, icon: BreweryPin, markerType: 'crafter'},
    {id: '96', title: 'Ugly Apple Cider and Wines', locationCode: 'UACAW', type: 'cidery', est: '2000', town: 'Lanark', province: 'Ontario', home: 'https://uglyapple.ca', homeDisplay: 'uglyapple.ca', maplink: 'https://g.page/ugly_apple?share', twitter: 'https://twitter.com/OnHighlands/status/1042484722121760768', instagram: 'https://www.instagram.com/ugly_apple/', lat: 45.040968, lng:-76.511908, icon: CideryPin, markerType: 'crafter'},
    {id: '97', title: 'Van Beer Co.', locationCode: 'VBEC', type: 'brewery', est: 'March 2016', town: 'Ottawa', province: 'Ontario', home: 'https://www.vanbeer.ca', homeDisplay: 'vanbeer.ca', maplink: 'https://goo.gl/maps/Yth5pkhj9m5ptvjB6', twitter: 'https://twitter.com/brownvanbrewing', instagram: 'https://www.instagram.com/vanbeerco/', lat: 45.395765, lng:-75.601554, icon: BreweryPin, markerType: 'crafter'},
    {id: '98', title: 'Vankleek Hill Vineyard', locationCode: 'VHV', type: 'winery', est: 'October 2019', town: 'Vankleek Hill', province: 'Ontario', home: 'https://www.vankleekhillvineyard.com', homeDisplay: 'vankleekhillvineyard.com', maplink: 'https://g.page/VankleekHillVineyard?share', twitter: 'https://twitter.com/MartyKral', instagram: 'https://www.instagram.com/vkhvineyard/', lat: 45.538939, lng:-74.549979, icon: WineryPin, markerType: 'crafter'},
    {id: '99', title: 'Vignoble Clos Du Vully', locationCode: 'VCDV', type: 'winery', est: 'May 2016', town: 'Ottawa', province: 'Ontario', home: 'https://www.vignobleclosduvully.com', homeDisplay: 'vignobleclosduvully.com', maplink: 'https://goo.gl/maps/ctCayM6xvwa6kApMA', twitter: 'https://twitter.com/ClosVully', instagram: 'https://www.instagram.com/closduvullywine/', lat: 45.410907, lng:-75.349843, icon: WineryPin, markerType: 'crafter'},
    {id: '100', title: 'Vimy Brewing Company', locationCode: 'VBRC', type: 'brewery', est: 'May 2017', town: 'Ottawa', province: 'Ontario', home: 'http://www.vimybrewing.ca', homeDisplay: 'vimybrewing.ca', maplink: 'https://goo.gl/maps/kxdTDP37AHNFXDvZ7', twitter: 'https://twitter.com/VimyBrewing', instagram: 'https://www.instagram.com/vimybrewing/', lat: 45.404231, lng:-75.715765, icon: BreweryPin, markerType: 'crafter'},
    {id: '101', title: 'Waller St. Brewing', locationCode: 'WSBR', type: 'brewery', est: '7 August 2015', town: 'Ottawa', province: 'Ontario', home: 'http://www.wallerst.ca', homeDisplay: 'wallerst.ca', maplink: 'https://goo.gl/maps/jtMo11QYcmxdixpa8', twitter: 'https://twitter.com/WallerStBrewing', instagram: 'https://www.instagram.com/wallerstbrewing/', lat: 45.427034, lng:-75.688735, icon: BreweryPin, markerType: 'crafter'},
    {id: '102', title: 'Weatherhead Brew Co.', locationCode: 'WBRC', type: 'brewery', est: '15 June 2019', town: 'Perth', province: 'Ontario', home: 'https://www.weatherheadbrewco.com', homeDisplay: 'weatherheadbrewco.com', maplink: 'https://goo.gl/maps/eruw8Avorm3Cnhqf6', twitter: 'https://twitter.com/weatherheadbrew', instagram: 'https://www.instagram.com/weatherheadbrewco/', lat: 44.901514, lng:-76.246121, icon: BreweryPin, markerType: 'crafter'},
    {id: '103', title: 'Westport Brewing Co.', locationCode: 'WEBC', type: 'brewery', est: '6 October 2018', town: 'Westport', province: 'Ontario', home: 'https://www.westportbrewingcompany.ca', homeDisplay: 'westportbrewingcompany.ca', maplink: 'https://goo.gl/maps/yBUDa8ERQzEYFfxJ8', twitter: 'https://twitter.com/WestportBrew', instagram: 'https://www.instagram.com/westportbrewingcompany/', lat: 44.67831, lng:-76.394813, icon: BreweryPin, markerType: 'crafter'},
    {id: '104', title: 'Whiprsnapr Brewing Co.', locationCode: 'WHBC', type: 'brewery', est: 'October 2015', town: 'Nepean', province: 'Ontario', home: 'https://whiprsnaprbrewingco.com', homeDisplay: 'whiprsnaprbrewingco.com', maplink: 'https://goo.gl/maps/4a2YHmKjmozEGL8L6', twitter: 'https://twitter.com/whiprsnaprbrew', instagram: 'https://www.instagram.com/whiprsnaprbrew/', lat: 45.330003, lng:-75.819445, icon: BreweryPin, markerType: 'crafter'},
    {id: '105', title: 'Whitewater Brewing - Lakeside', locationCode: 'WWB-L', type: 'brewery', est: 'November 2016', town: 'Cobden', province: 'Ontario', home: 'https://whitewaterbeer.ca', homeDisplay: 'whitewaterbeer.ca', maplink: 'https://goo.gl/maps/bCWrYxCCo6PvdZK7A', twitter: 'https://twitter.com/WhitewaterBrew', instagram: 'https://www.instagram.com/whitewaterbrewingco/', lat: 45.633593, lng:-76.886937, icon: BreweryPin, markerType: 'crafter'},
    {id: '106', title: 'Whitewater Brewing - Riverside', locationCode: 'WWB-R', type: 'brewery', est: 'June 2013', town: 'Foresters Falls', province: 'Ontario', home: 'https://whitewaterbeer.ca', homeDisplay: 'whitewaterbeer.ca', maplink: 'https://goo.gl/maps/kkBjZQgonMHuWi2Z8', twitter: 'https://twitter.com/WhitewaterBrew', instagram: 'https://www.instagram.com/whitewaterbrewingco/', lat: 45.725582, lng:-76.773643, icon: BreweryPin, markerType: 'crafter'},
    {id: '107', title: 'Windmill Brewery', locationCode: 'WIBR', type: 'brewery', est: '12 November 2016', town: 'Johnstown', province: 'Ontario', home: 'http://windmill.beer', homeDisplay: 'windmill.beer', maplink: 'https://goo.gl/maps/zcowqaLgcGbWHv4e7', twitter: 'https://twitter.com/Windmill_Beer', instagram: 'https://www.instagram.com/windmillbrewery/', lat: 44.723526, lng:-75.488873, icon: BreweryPin, markerType: 'crafter'},
    {id: '108', title: 'Wood Brothers Brewing Company', locationCode: 'WBBC', type: 'brewery', est: '1 June 2019', town: 'Glen Robertson', province: 'Ontario', home: 'https://woodbrothersbrewingtogo.com', homeDisplay: 'woodbrothersbrewingtogo.com', maplink: 'https://goo.gl/maps/tXnTg7A57SGW3H6y9', twitter: 'https://twitter.com/BrewingWood', instagram: 'https://www.instagram.com/woodbrothersbrewing2018/', lat: 45.370774, lng:-74.546606, icon: BreweryPin, markerType: 'crafter'},
    {id: '109', title: 'Needham\'s Market Garden', locationCode: 'NMG', type: 'winery', est: '2000', town: 'Arnprior', province: 'Ontario', home: 'https://www.needhamsmarketgarden.com/', homeDisplay: 'needhamsmarketgarden.com', maplink: 'https://g.page/NeedhamsMarketGarden?share', twitter: 'https://twitter.com/NeedhamsGarden', instagram: 'https://www.instagram.com/needhamsmarketgarden/', lat: 45.391915, lng:-76.310547, icon: WineryPin, markerType: 'crafter'},
    {id: '110', title: 'Les Vergers Villeneuve and Blueberry Farm', locationCode: 'LVV', type: 'winery', est: '1998', town: 'Saint-Pascal-Baylon', province: 'Ontario', home: 'http://vergersvilleneuve.com/', homeDisplay: 'vergersvilleneuve.com', maplink: 'https://goo.gl/maps/Y4qpGV3NvSMs5be57', twitter: 'https://twitter.com/search?q=%22Vergers%20Villeneuve%22&f=live', instagram: 'https://www.instagram.com/vergersvilleneuve/', lat: 45.537834, lng:-75.160836, icon: WineryPin, markerType: 'crafter'},
    {id: '111', title: 'Good Prospects Brewing Company', locationCode: 'GPBC', type: 'brewery', est: '28 January 2021', town: 'Ottawa', province: 'Ontario', home: 'https://www.goodprospects.ca/', homeDisplay: 'goodprospects.ca', maplink: 'https://goo.gl/maps/ASueK7sXNBtPv3fp7', twitter: 'https://twitter.com/prospects_good', instagram: 'https://www.instagram.com/goodprospectsbrewing/', lat: 45.4478631201279, lng:-75.6506948425924, icon: BreweryPin, markerType: 'crafter'},
];

class MapComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            eventsResponseParsed: false,
            modalVisible: false,
            keyModalVisible: false,
            crafters: [],
            crafterEventDataset: [],
            eventEventDataset: [],
            crafterPinEvents: [],
            chosenEvent: {
                title: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                allDay: false,
                location: '',
                locationName: '',
                imageLink: '',
                description: ''
            }
        }
    }

    static defaultProps = {
        center: {lat: 45.2, lng: -76},
        defaultzoom: 9,
        minZoom: 6,
        maxZoom: 17,
        bounds: [[41.114478, -87.861475], [48.649995, -64.144155]] //southwest, northeast
    };

    componentDidMount() {
        //this.leafletMap is my map reference
        this.setState({ crafters: crafters });
        this.getEvents();
        this.addSearch();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !areEqual(this.state, nextState) || !areEqual(this.props, nextProps);
    }

    getEvents() {
        let that = this;
        let gapi = window.gapi;
        function start() {
            gapi.client
                .init({'apiKey': 'AIzaSyAnbfHuZJAyJVajBlHYiH2nyH_96xrebq4'})
                .then(function () {
                    return gapi.client
                        .request({
                            'path': 'https://www.googleapis.com/calendar/v3/calendars/9hc8npmpfgko5prmrpal42j7bg@group.calendar.google.com/events'
                        })
                })
                .then((response) => {
                    let result = response.result.items;
                    let events = [];
                    let crafterEventDataset = [];
                    let eventEventDataset = [];
                    let crafterPinEvents = [];
                    let craftEventCount = 0;
                    let eventEventCount = 0;
                    let today = new Date();
                    let maxDateForPin = new Date();

                    for (let i = 0; i < result.length; i++) {
                        let eventStartDate = checkDate(result[i].start);
                        let eventEndDate = checkDate(result[i].end);
                        if (eventEndDate >= today) {
                            result[i].start = eventStartDate;
                            result[i].end = eventEndDate;
                            if (((Math.abs(eventEndDate - eventStartDate) / 36e5) % 24) === 0) {
                                result[i].allDay = true;
                                result[i]
                                    .end
                                    .setDate(result[i].end.getDate() - 1);
                            } else {
                                result[i].allDay = false;
                            }
                            events.push(result[i]);
                        }
                    }
                    events.sort(function (a, b) {
                            var dateA = new Date(a.start),
                                dateB = new Date(b.start);
                            return dateA - dateB;
                        });
                    maxDateForPin.setDate(today.getDate() + 56); // Eight weeks
                    for(let i = 0; i < events.length; i++) {
                        let eventStartDate = events[i].start;
                        let eventEndDate = events[i].end;
                        if (eventStartDate <= maxDateForPin) {
                            if (events[i].description.split('/~').pop().split('~/')[0] !== 'event') {
                                let crafterPinEvent = {};
                                crafterPinEvent = {
                                    id: events[i].id,
                                    title: events[i].summary,
                                    location: events[i].location,
                                    locationName: events[i].location.split(',')[0],
                                    locationCode: events[i].description.split('/~').pop().split('~/')[0],
                                    startDate: eventStartDate,
                                    startTime: eventStartDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}).replace(/^0(?:0:0?)?/, ''),
                                    endDate: eventEndDate,
                                    endTime: eventEndDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}).replace(/^0(?:0:0?)?/, ''),
                                    allDay: events[i].allDay,
                                    imageLink: events[i].description.substring(events[i].description.lastIndexOf('{') + 1, events[i].description.lastIndexOf('}')),
                                    description: that.stringToHTML(events[i].description.split('///')[0])
                                };
                                crafterPinEvents.push({
                                    id: events[i].id,
                                    locationCode: events[i].description.split('/~').pop().split('~/')[0]
                                });
                                let locationCode = events[i].description.split('/~').pop().split('~/')[0];
                                let inCrafterArray = false;
                                if (!crafterEventDataset.length) {
                                    crafterEventDataset[craftEventCount] = [locationCode, crafterPinEvent];
                                    craftEventCount++;
                                    inCrafterArray = true;
                                } else {
                                    for(let j = 0; j < crafterEventDataset.length; j++) {
                                        if (crafterEventDataset[j][0] === locationCode) {
                                            let innerLength = crafterEventDataset[j].length;
                                            crafterEventDataset[j][innerLength] = crafterPinEvent;
                                            inCrafterArray = true;
                                        }
                                    }
                                }
                                if (!inCrafterArray) {
                                    crafterEventDataset[craftEventCount] = [locationCode, crafterPinEvent];
                                    craftEventCount++;
                                }
                            } else {
                                let eventPinEvent = {};
                                eventPinEvent = {
                                    id: events[i].id,
                                    title: events[i].summary,
                                    location: events[i].location,
                                    locationName: events[i].location.split(',')[0],
                                    locationCode: events[i].description.split('/~').pop().split('~/')[0],
                                    type: 'event',
                                    startDate: eventStartDate,
                                    startTime: eventStartDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}).replace(/^0(?:0:0?)?/, ''),
                                    endDate: eventEndDate,
                                    endTime: eventEndDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}).replace(/^0(?:0:0?)?/, ''),
                                    allDay: events[i].allDay,
                                    imageLink: events[i].description.substring(events[i].description.lastIndexOf('{') + 1, events[i].description.lastIndexOf('}')),
                                    description: that.stringToHTML(events[i].description.split('///')[0]),
                                };
                                let locationName = events[i].location.split(',')[0];
                                let lat = parseFloat(events[i].description.split('latitude:').pop().split('/')[0]);
                                let lng = parseFloat(events[i].description.split('longitude:').pop().split('/')[0]);
                                let inEventArray = false;
                                if (!eventEventDataset.length) {
                                    eventEventDataset[eventEventCount] = [locationName, lat, lng, eventPinEvent];
                                    eventEventCount++;
                                    inEventArray = true;
                                } else {
                                    for(let k = 0; k < eventEventDataset.length; k++) {
                                        if (eventEventDataset[k][0] === locationName) {
                                            let innerLength = eventEventDataset[k].length;
                                            eventEventDataset[k][innerLength] = eventPinEvent;
                                            inEventArray = true;
                                        }
                                    }
                                }
                                if (!inEventArray) {
                                    eventEventDataset[eventEventCount] = [locationName, lat, lng, eventPinEvent];
                                    eventEventCount++;
                                }
                            }
                        }
                    }
                    that.setState({
                        crafterPinEvents: crafterPinEvents,
                        crafterEventDataset: crafterEventDataset,
                        eventEventDataset: eventEventDataset,
                        eventsResponseParsed: true
                    });
                    that.leafletMap.setMaxBounds(that.props.bounds);
                }, function (reason) {
                    console.log('Error: ' + reason.result.error.message);
                    Swal.fire({icon: 'error', text: 'Event & Festival markers could not be loaded at this time.', footer: '<sub>Please contact:<a id="error-contact" href="mailto:contact@craftottawa.ca" rel="author" alt="email link for contact@craftottawa.ca">contact@craftottawa.ca</a></sub>'})
                });
        }
        setTimeout(function () {
            gapi.load('client', start);
        }, 100);

        function checkDate(date) {
            let result = new Date();
            let checkDate = date;
            if (checkDate.dateTime === undefined) {
                result = new Date(checkDate.date.split('-').join('/'));
            } else {
                result = new Date(checkDate.dateTime);
            }
            return result;
        }
    }


    activateModal = () => {
        this.setState({modalVisible: true});
    };

    deactivateModal = () => {
        this.setState({modalVisible: false});
    };

    toggleKeyModal = () => {
        this.setState(state => ({
            keyModalVisible: !state.keyModalVisible
        }));
    };

    handleClick = (details) => {
        this.setState({
            chosenEvent: {
                id: details.id,
                title: details.title,
                startDate: details.startDate,
                startTime: details.startTime,
                endDate: details.endDate,
                endTime: details.endTime,
                allDay: details.allDay,
                location: details.location,
                locationName: details.locationName,
                imageLink: details.imageLink,
                description: details.description
            }
        });
        this.activateModal();
    };

    addSearch() {
        const craftersPins = crafters;
        var searchLayer = L.layerGroup().addTo(this.leafletMap);
        craftersPins.forEach(crafterMarker => {
            let title = crafterMarker.title.replace('Ç', 'C').replace('À', 'A').replace('Â', 'A').replace('É', 'E').replace('È', 'E').replace('Ê', 'E').replace('Ë', 'E').replace('Ï', 'I').replace('Î', 'I').replace('Ô', 'O').replace('Ù', 'U').replace('Û', 'U').replace('Ü', 'U').replace('Ÿ', 'Y').replace('ç', 'c').replace('à', 'a').replace('â', 'a').replace('é', 'e').replace('è', 'e').replace('ê', 'e').replace('ë', 'e').replace('ï', 'i').replace('î', 'i').replace('ô', 'o').replace('ù', 'u').replace('û', 'u').replace('ü', 'u').replace('ÿ', 'y') + ", " + crafterMarker.town.replace('Ç', 'C').replace('À', 'A').replace('Â', 'A').replace('É', 'E').replace('È', 'E').replace('Ê', 'E').replace('Ë', 'E').replace('Ï', 'I').replace('Î', 'I').replace('Ô', 'O').replace('Ù', 'U').replace('Û', 'U').replace('Ü', 'U').replace('Ÿ', 'Y').replace('ç', 'c').replace('à', 'a').replace('â', 'a').replace('é', 'e').replace('è', 'e').replace('ê', 'e').replace('ë', 'e').replace('ï', 'i').replace('î', 'i').replace('ô', 'o').replace('ù', 'u').replace('û', 'u').replace('ü', 'u').replace('ÿ', 'y');
            let location = [crafterMarker.lat, crafterMarker.lng];
            var marker = new L.Marker(new L.latLng(location), {icon: searchSpot, title: title});
            searchLayer.addLayer(marker);
        })
        this.leafletMap.addControl(L.control.search({layer: searchLayer, initial: false, autoCollapse: true, zoom: 14, delayType: 200, textErr: 'Crafter not found'}));
    }

    stringToHTML = val => {
        const clean = DOMPurify.sanitize(val);
        return clean;
    }

    eventCheck = marker => {
        let icon = marker.icon;
        let check = marker;
        const { crafterPinEvents } = this.state;
        if (Array.isArray(crafterPinEvents) && crafterPinEvents.length) {
            for (let i = 0; i < crafterPinEvents.length; i++) {
                if (check.locationCode === crafterPinEvents[i].locationCode) {
                    switch(check.type) {
                    case "brewery":
                        icon = StarBreweryPin;
                        break;
                    case "cidery":
                        icon = StarCideryPin;
                        break;
                    case "distillery":
                        icon = StarDistilleryPin;
                        break;
                    case "meadery":
                        icon = StarMeaderyPin;
                        break;
                    case "store":
                        icon = StarStorePin;
                        break;
                    case "winery":
                        icon = StarWineryPin;
                        break;
                    case "event":
                        icon = EventPin;
                        break;
                    default:
                        break;
                    }
                }
            }
        }
        return icon;
    };

    createEventPopup = eventMarker => {
        let that = this;
        const { eventEventDataset } = this.state;

        function buildEventsEvents(i) {
            let eepArray = [];
            let index = i;
            for (let j = 3; j < eventEventDataset[index].length; j++) {
                var eeTitleP = React.createElement('p', {key: 0, id: 'event-title'}, eventEventDataset[index][j].title);
                eepArray.push(eeTitleP);
                var eeStartP;
                if (eventEventDataset[index][j].allDay) {
                    if ((Math.abs(eventEventDataset[index][j].endDate - eventEventDataset[index][j].startDate) / 36e5) === 0) {
                        eeStartP = React.createElement('p', {key: 1, id: 'event-start-date'}, moment(eventEventDataset[index][j].startDate).format('ddd MMM Do'));
                    } else {
                        eeStartP = React.createElement('p', {key: 2, id: 'event-start-date'}, moment(eventEventDataset[index][j].startDate).format('ddd MMM Do')+ " - " + moment(eventEventDataset[index][j].endDate).format('ddd MMM Do'));
                    }
                } else if (eventEventDataset[index][j].startDate.toDateString() === eventEventDataset[index][j].endDate.toDateString()){
                    eeStartP = React.createElement('p', {key: 3, id: 'event-start-date'}, moment(eventEventDataset[index][j].startDate).format('ddd MMM Do'));
                } else {
                    eeStartP = React.createElement('p', {key: 4, id: 'event-start-date'}, moment(eventEventDataset[index][j].startDate).format('ddd MMM Do')+ " - " + moment(eventEventDataset[index][j].endDate).format('ddd MMM Do'));
                }
                eepArray.push(eeStartP);
                var eeEventLink = React.createElement('a', {key: 5, id: 'event-link', href: '#', onClick: () => {that.handleClick(eventEventDataset[index][j])}}, "Details");
                eepArray.push(eeEventLink);
            }
            let eEvents = React.createElement(React.Fragment, null, eepArray);
            return eEvents;
        }

        for (let i = 0; i < eventEventDataset.length; i++) {
            if (eventMarker[0] === eventEventDataset[i][0]) {
                return (
                    <Popup>
                        <p id='location-title'>{eventMarker[0]}</p>
                        <div id='events-list'>
                            <div id='events-name'>
                                <img id='calendar-icon' src={CalendarIcon} alt="icon of a calendar with a heart on it" width="17" height="17"/>
                                <span>Events</span>
                            </div>
                            {buildEventsEvents(i)}
                        </div>
                    </Popup>
                )
            }
        }
    };

    crafterPopupDetails = marker => {
        return (
            <Fragment>
                <p id='location-icons' className='pt-0 pb-0 m-1 centered'>
                    <a id='maplink' href={marker.maplink} target='_blank' rel='noopener noreferrer'><img title='Google Maps' src={MapsIcon} alt="icon link to the crafter's location in Google Maps" width="17" height="17"/></a>
                    <a id='twitterlink' href={marker.twitter} target='_blank' rel='noopener noreferrer'><img title='Twitter' src={TwitterIcon} alt="icon link to the crafter's Twitter account" width="17" height="17"/></a>
                    <a href={marker.instagram} target='_blank' rel='noopener noreferrer'><img src={InstagramIcon} title='Instagram' alt="icon link to the crafter's Instagram account" width="17" height="17"/></a>
                </p>
                <p id='location-title'>{marker.title}</p>
                <p id='location-est' className='pt-0 pb-1 m-1'>Since {marker.est}</p>
                <p id='location-home' className='pt-0 pb-1 m-1'><a href={marker.home} target='_blank' rel='noopener noreferrer'>{marker.homeDisplay}</a></p>
            </Fragment>
        )
    };

    createCrafterPopup = crafterMarker => {
        let that = this;
        const { crafterEventDataset } = this.state;
        var matchedIconWithEvents = false;
        function buildCrafterEvents(i) {
            let cepArray = [];
            let index = i;
            for (let j = 1; j < crafterEventDataset[index].length; j++) {
                var ceTitleP = React.createElement('p', {key: 6, id: 'event-title'}, crafterEventDataset[index][j].title);
                cepArray.push(ceTitleP);
                var ceStartP;
                if (crafterEventDataset[index][j].allDay) {
                    if ((Math.abs(crafterEventDataset[index][j].endDate - crafterEventDataset[index][j].startDate) / 36e5) === 0) {
                        ceStartP = React.createElement('p', {key: 7, id: 'event-start-date'}, moment(crafterEventDataset[index][j].startDate).format('ddd MMM Do'));
                    } else {
                        ceStartP = React.createElement('p', {key: 8, id: 'event-start-date'}, moment(crafterEventDataset[index][j].startDate).format('ddd MMM Do')+ " - " + moment(crafterEventDataset[index][j].endDate).format('ddd MMM Do'));
                    }
                } else if (crafterEventDataset[index][j].startDate.toDateString() === crafterEventDataset[index][j].endDate.toDateString()){
                    ceStartP = React.createElement('p', {key: 9, id: 'event-start-date'}, moment(crafterEventDataset[index][j].startDate).format('ddd MMM Do'));
                } else {
                    ceStartP = React.createElement('p', {key: 10, id: 'event-start-date'}, moment(crafterEventDataset[index][j].startDate).format('ddd MMM Do')+ " - " + moment(crafterEventDataset[index][j].endDate).format('ddd MMM Do'));
                }
                cepArray.push(ceStartP);
                var ceEventLink = React.createElement('a', {key: 11, id: 'event-link', onClick: () => {that.handleClick(crafterEventDataset[index][j])}}, "Details");
                cepArray.push(ceEventLink);
            }
            let cEvents = React.createElement(React.Fragment, null, cepArray);
            return cEvents;
        }

        for (let i = 0; i < crafterEventDataset.length; i++) {
            if (crafterMarker.locationCode === crafterEventDataset[i][0]) {
                matchedIconWithEvents = true;
                return (
                    <Popup>
                        {this.crafterPopupDetails(crafterMarker)}
                        <div id='events-list'>
                            <div id='events-name'>
                                <img id='calendar-icon' src={CalendarIcon} alt="icon of a calendar with a heart on it" width="17" height="17"/>
                                <span>Events</span>
                            </div>
                            {buildCrafterEvents(i)}
                        </div>
                    </Popup>
                )
            }
        }
        if (!matchedIconWithEvents) {
            return (
                <Popup>
                    {this.crafterPopupDetails(crafterMarker)}
                </Popup>
            )
        }
    };

    clearAll = () => {
        $('.leaflet-control-layers-overlays').find('input[type=checkbox]:checked').trigger('click');
    }

    onLocationError = () => {
        Swal.fire({icon: 'error', text: 'Your current location could not be found, or it is outside of the Map\'s boundaries.', footer: '<a id="location-sharing-link" href="https://help.flickr.com/en_us/enable-the-location-features-on-your-mobile-device-Sy5nhmokX" target="_blank" rel="external noopener noreferrer">Enable location features</a>'})
    };

    setLeafletMapRef = map => (this.leafletMap = map && map.leafletElement);

    invalidateMap = () => setTimeout(() => {this.leafletMap.invalidateSize(true);}, 180);

    render() {
        const {crafters, eventEventDataset, modalVisible, keyModalVisible} = this.state;
        const center = this.props.center;
        const minZoom = this.props.minZoom;
        const maxZoom = this.props.maxZoom;
        const zoom = this.props.defaultzoom;
        const bounds = this.props.bounds;
        const locateOptions = {
            position: 'topleft',
            strings: {
                title: 'My Location',
                outsideMapBoundsMsg: "Your location is outside of the Map's boundaries."
            },
            onLocationError: this.onLocationError,
            drawCircle: false,
            //Note that this can result in slower response times or increased power consumption.
            //enableHighAccuracy: true,
            setView: false,
            flyTo: true,
            clickBehavior: {inView: 'stop', outOfView: 'setView', inViewNotFollowing: 'inView'},
            keepCurrentZoomLevel: true,
            showCompass: false,
            showPopup: false,
            onActivate: () => {} //callback before engine starts retrieving locations
        }

        return (
            <Fragment>
                <div id='map-container'>
                    <Map
                        id='map'
                        ref={this.setLeafletMapRef}
                        preferCanvas={true}
                        center={center}
                        zoom={zoom}
                        onResize={this.invalidateMap}
                        whenReady={this.invalidateMap}
                        attributionControl={false}
                        >
                        <Control position="topright">
                            <button id="clear-all-markers" onClick={this.clearAll}>Clear Markers</button>
                        </Control>
                        <AttributionControl position="bottomleft"/>
                        <LocateControl options={locateOptions}/>
                            <LayersControl position="topright">
                                <BaseLayer checked name="Colour Map">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                        errorTileUrl='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
                                        bounds={bounds}
                                        minZoom={minZoom}
                                        maxZoom={maxZoom}
                                    />
                                </BaseLayer>
                                <BaseLayer name="Cycling Map">
                                    <TileLayer
                                        attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        url='https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=d93ce6fb6dd148a59dc72934a72c1ae3'
                                        apikey='d93ce6fb6dd148a59dc72934a72c1ae3'
                                        errorTileUrl='https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
                                        bounds={bounds}
                                        minZoom={minZoom}
                                        maxZoom={maxZoom}
                                    />
                                </BaseLayer>
                                <BaseLayer name="Black &amp; White">
                                    <TileLayer
                                        attribution='&amp;copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://wikitech.wikimedia.org/wiki/Wikitech:Cloud_Services_Terms_of_use">Wikimedia Cloud Services</a>'
                                        url='https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'
                                        errorTileUrl='https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png'
                                        bounds={bounds}
                                        minZoom={minZoom}
                                        maxZoom={maxZoom}
                                    />
                                </BaseLayer>
                                <BaseLayer name="Watercolour">
                                    <TileLayer
                                        attribution='Tiles <a href="http://stamen.com">Stamen Design</a> <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>, data <a href="http://openstreetmap.org">OpenStreetMap</a> <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
                                        url='https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png'
                                        bounds={bounds}
                                        minZoom={minZoom}
                                        maxZoom={maxZoom}
                                    />
                                </BaseLayer>
                                {eventEventDataset.map(eventMarker => { // Events:
                                    if (L.Browser.touch) {
                                        return (<Marker
                                            key={uid.randomUUID(3)}
                                            position={{lat:eventMarker[1], lng:eventMarker[2]}}
                                            icon={EventPin}>
                                            {this.createEventPopup(eventMarker)}
                                        </Marker>)
                                    } else {
                                        return (<Marker
                                            key={uid.randomUUID(3)}
                                            position={{lat:eventMarker[1], lng:eventMarker[2]}}
                                            icon={EventPin}>
                                            <Tooltip offset={[0, -5]} opacity={0.8}>{eventMarker[0]}</Tooltip>
                                            {this.createEventPopup(eventMarker)}
                                        </Marker>)
                                    }
                                })}
                                <Overlay name="<img src=/static/media/pin_brewery.a9e929d3.svg alt='icon indicating brewery' width=24 height=24/> Brewery" checked="true">
                                    <LayerGroup id="brewery-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'brewery') { // Brewery:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                                <Overlay name="<img src=/static/media/pin_cidery.e7abd59a.svg alt='icon indicating cidery' width=24 height=24/> Cidery" checked="true">
                                    <LayerGroup id="cidery-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'cidery') { // Cidery:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                                <Overlay name="<img src=/static/media/pin_distillery.3ef4f9da.svg alt='icon indicating distillery' width=24 height=24/> Distillery" checked="true">
                                    <LayerGroup id="distillery-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'distillery') { // Distillery:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                                <Overlay name="<img src=/static/media/pin_meadery.53c57115.svg alt='icon indicating meadery' width=24 height=24/> Meadery" checked="true">
                                    <LayerGroup id="meadery-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'meadery') { // Meadery:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                                <Overlay name="<img src=/static/media/pin_winery.39657c6b.svg alt='icon indicating winery' width=24 height=24/> Winery" checked="true">
                                    <LayerGroup id="winery-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'winery') { // Winery:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                                <Overlay name="<img src=/static/media/pin_store.66f2af72.svg alt='icon indicating store specializing in carrying craft liquor' width=24 height=24/> Store" checked="true">
                                    <LayerGroup id="store-layer">
                                        {crafters.map(crafterMarker => {
                                            if (crafterMarker.type === 'store') { // Store:
                                                if (L.Browser.touch) {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                } else {
                                                    return (<Marker
                                                        key={crafterMarker.id}
                                                        position={{lat:crafterMarker.lat, lng:crafterMarker.lng}}
                                                        icon={this.eventCheck(crafterMarker)}>
                                                        <Tooltip offset={[0, -5]} opacity={0.75}>{crafterMarker.title}</Tooltip>
                                                        {this.createCrafterPopup(crafterMarker)}
                                                    </Marker>)
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </LayerGroup>
                                </Overlay>
                            </LayersControl>
                    </Map>
                </div>
                <div>
                    <Link id='event-notice' to='/addevents'>
                        <img src={EventIcon} alt="event icon" width="18" height="18"/><span id='upcoming-event'>Upcoming Event</span><br/>Submit an Event
                    </Link>
                </div>
                <div id='cycling-key-link'>
                    <button id='cycling-key-button' type='button' onClick={this.toggleKeyModal}><span role='img' aria-label='Woman Biking'>🚴‍♀️ </span>Cycling Map key</button>
                    {keyModalVisible && (<CyclingKeyPopup onHide={this.toggleKeyModal}/>)}
                </div>
                {modalVisible && (<EventPopup
                    popupDetails={this.state.chosenEvent}
                    onHide={this.deactivateModal}/>)}
            </Fragment>
        )
    }
}

MapComponent.propTypes = {
    bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    center: PropTypes.objectOf(PropTypes.number),
    defaultzoom: PropTypes.number,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
};

export default MapComponent;