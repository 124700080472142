import React from 'react';
import AriaModal from 'react-aria-modal';
import ModalImage from 'react-modal-image';
import moment from 'moment';
import {PropTypes} from 'prop-types';

const eventDatesAndTimes = (info) => {
    if (info.allDay) {
        if ((Math.abs(info.endDate - info.startDate) / 36e5) === 0) {
            return (moment(info.startDate).format('dddd MMMM Do'));
        } else {
            return (moment(info.startDate).format('dddd MMMM Do') + " - " + moment(info.endDate).format('dddd MMMM Do'));
        }

    } else {
        if ((info.endTime === 'no end time' && info.endDate === 'same as start date')) {
            return ('Starts at ' + info.startTime);
        } else {
            if (info.startDate.toDateString() === info.endDate.toDateString()) {
                return (
                    <div>
                        <div>{moment(info.startDate).format('dddd MMMM Do')}</div>
                        <div>{info.startTime + ' - ' + info.endTime}</div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div>{moment(info.startDate).format('dddd MMMM Do') + ' at ' + info.startTime + ' - '}</div>
                        <div>{moment(info.endDate).format('dddd MMMM Do') + ' at ' + info.endTime}</div>
                    </div>
                );
            }
        }
    }
};

const getTextFromHyperlink = linkText => {
    return linkText.match(/<a [^>]+>([^<]+)<\/a>/)[1];
}

const renderImage = (imageLink) => {
    if (imageLink !== null && imageLink !== 'null' && imageLink !== '') {
        let link = getTextFromHyperlink(imageLink);
        return (
            <ModalImage
            className='event-image'
            small={link}
            medium={link}
            smallSrcSet={link}
            hideDownload={true}
            hideZoom={true}/>
        )
    }
}

const EventPopup = ({popupDetails, onHide}) => (
    <AriaModal
        titleId='popup-event'
        titleText={popupDetails.title}
        onExit={onHide}
        underlayClickExits={true}
        focusDialog={true}
        escapeExits={true}
        scrollDisabled={true}
        dialogClass="popup-event"
        verticallyCenter={true}>
        <div className='mw-modal'>
            <div id='event-header' className='modal-header'>
                {popupDetails.title}
            </div>
            <div id='header-location' className='modal-header'>
                <a href={'https://maps.google.com/?q=' + popupDetails.location} rel='external'>{popupDetails.locationName}</a>
            </div>
            <div id='header-location-address' className='modal-header'>
                {popupDetails
                    .location
                    .split(', ')[1] + ", " + popupDetails
                    .location
                    .split(', ')[2] + " " + popupDetails
                    .location
                    .split(', ')[3]
                    .slice(0, 2)}
                <br/>
                <br/>
                <div id='event-dates-and-times'>{eventDatesAndTimes(popupDetails)}</div>
            </div>
            <div id='event-body' className='modal-body'>
                {renderImage(popupDetails.imageLink)}
                <br/>
                <div
                    id='event-description'
                    dangerouslySetInnerHTML={{
                    __html: popupDetails.description
                }}/>
            </div>
            <div id='event-back'>
                <button className='btn btn-secondary' onClick={onHide}>
                    Back
                </button>
            </div>
            <div id='event-footer' className='modal-footer'>
                Details are subject to change by organizer.
            </div>
        </div>
    </AriaModal>
)

EventPopup.propTypes = {
    popupDetails: PropTypes.object,
    onHide: PropTypes.func
};

export default EventPopup;