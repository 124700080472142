import React from 'react';
import AriaModal from 'react-aria-modal';
import { PropTypes } from 'prop-types';
import CyclingKey from './images/cycling-layer-key.png';

const CyclingKeyPopup = ({onHide}) => (
    <AriaModal
        titleId='popup-cycling-legend'
        titleText='Cycling Layer Key'
        onExit={onHide}
        underlayClickExits={true}
        focusDialog={true}
        escapeExits={true}
        dialogClass="cycling-key"
        verticallyCenter={true}
        scrollDisabled={true}
        underlayStyle={{ paddingTop: '42px', paddingBottom: '26px' }}
        >
        <div>
            <img src={CyclingKey} className='non-draggable-image' alt="OpenCycleMap key for the cycling map layer" id='cycling-key-image' draggable='false'/>
            <div id='back-button'>
                <button className="btn btn-secondary" onClick={onHide}>Back</button>
            </div>
        </div>
    </AriaModal>
)

CyclingKeyPopup.propTypes = {
    popupDetails: PropTypes.object,
    onHide: PropTypes.func
};

export default CyclingKeyPopup;