import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import {Link} from "@reach/router";
import ScrollToTopButton from "./ScrollToTopButton";

class SubmitEvent extends React.Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Craft Ottawa - Submit Event</title>
                    <meta
                    name="description"
                    content="Complete this form to submit an event for Craft Ottawa's Map and Events &amp; Festivals Calendar."/>
                    <meta property="og:title" content="Craft Ottawa - Submit Event"/>
                    <meta
                        property="og:description"
                        content="Complete this form to submit an event for Craft Ottawa's Map and Events &amp; Festivals Calendar."/>
                </Helmet>
                <div id='add-an-event' className='container'>
                    <div className="heading">
                        Complete this form or return to <Link to='/addevents'>Add Events</Link>
                    </div>
                    <iframe
                        src='https://docs.google.com/forms/d/e/1FAIpQLSfj5Wurzw7PvijQj951GNizVaqy51C2fRtxtLJ6bs9MFDZL9w/viewform?embedded=true'
                        id='event-google-iframe'
                        title='event-form'
                        frameBorder='0'
                        marginHeight='0'
                        marginWidth='0'
                        scrolling='no'
                        width='100%'
                        height='5500px'>
                    </iframe>
                    <ScrollToTopButton/>
                </div>
            </Fragment>
        );
    }
}

export default SubmitEvent;