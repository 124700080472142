import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import {Link} from "@reach/router";
import EventIcon from './images/pin_event.svg';
import ExampleEventPin01 from './images/example-event-pin-01.PNG';
import PrivacyPolicyPDF from './pdf/Craft-Ottawa-Privacy-Policy.pdf';

class AddEvents extends React.Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Craft Ottawa - Add Events and Festivals</title>
                    <meta
                    name="description"
                    content="Want to add an event or festival to Craft Ottawa's Map and Calendar? Learn more."/>
                    <meta property="og:title" content="Craft Ottawa - Add Events"/>
                    <meta
                        property="og:description"
                        content="Want to add an event or festival to Craft Ottawa's Map and Calendar? Learn more."/>
                </Helmet>
                <div id='add-an-event' className='container'>
                    <div className="heading">Add an Event or Festival</div>
                    <br/>
                    <div>Visitors to this site are looking for more about our vibrant craft
                        liquor scene. Know of an upcoming event? Let's add it to&nbsp;
                        <span className='site-title'>Craft Ottawa</span>&nbsp;and we can satisfy their thirst
                        together <span role='img' aria-label='Clinking Glasses'>🥂</span>.</div>
                    <br/>
                    <div id='submit-event-div' className='technical-details w-75 p-3 centered bold'>
                        To submit an event <Link to='/submitevent'>complete this form</Link> or email the details to&nbsp;
                        <a href='mailto:contact@craftottawa.ca' alt='email link for contact@craftottawa.ca' rel='author noopener noreferrer' target='_blank'>contact@craftottawa.ca</a>
                    </div>
                    <br/>
                    <div>
                        Once&nbsp;<span className='site-title'>Craft Ottawa</span>&nbsp;has verified the details, the event will appear with a special marker and details link on the <span className="bold">Map</span>
                        <img src={EventIcon} alt="event icon" width="20" height="20"/> at the venue location for eight weeks until the big day.
                        The event will also be added to the <span className='bold'>Events</span> calendar.
                    </div>
                    <br/>
                    <div className='heading'>
                        <img src={ExampleEventPin01} alt="event icon"/>
                    </div>
                    <br/>
                    <div id='details-and-event-submit' className='technical-details w-75 p-3'>
                        The security of any submitted information is important to me. Read <span className='site-title'>Craft Ottawa</span>'s <a href={PrivacyPolicyPDF} alt='Link to PDF of the Craft Ottawa Privacy Policy' rel='author noopener noreferrer' target='_blank'>Privacy Policy</a> for details.
                    </div>
                    <br/>
                    <div className='centered'>If you have any questions please email me: <a
                        href='mailto:contact@craftottawa.ca'
                        rel='author'
                        alt='email link for contact@craftottawa.ca'>
                        contact@craftottawa.ca
                        </a>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default AddEvents;