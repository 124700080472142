import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import EventPopup from './EventPopup.js';
import FullCalendar from '@fullcalendar/react';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import DOMPurify from 'dompurify';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import Swal from 'sweetalert2';
import $ from 'jquery';
import './main.scss';

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            value: '',
            suggestions: [],
            chosenEvent: {
                title: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                allDay: false,
                location: '',
                locationName: '',
                imageLink: '',
                description: ''
            },
            searchData: [],
            isLoaded: false
        };
    }
    
    calendarRef = React.createRef();

    componentDidMount() {
        this.getEventsForSearch();
    }

    getEventsForSearch() {
        let that = this;
        let gapi = window.gapi;
        function start() {
            gapi.client
                .init({'apiKey': 'AIzaSyAnbfHuZJAyJVajBlHYiH2nyH_96xrebq4'})
                .then(function () {
                    return gapi.client
                        .request({
                            'path': 'https://www.googleapis.com/calendar/v3/calendars/9hc8npmpfgko5prmrpal42j7bg@group.calendar.google.com/events'
                        })
                })
                .then((response) => {
                    let result = response.result.items;
                    let events = [];
                    let today = new Date();
                    for (var i = 0; i < result.length; i++) {
                        let eventStartDate = checkDate(result[i].start);
                        let eventEndDate = checkDate(result[i].end);
                        if (eventEndDate >= today) {
                            result[i].start = eventStartDate;
                            result[i].end = eventEndDate;
                            if (((Math.abs(eventEndDate - eventStartDate) / 36e5) % 24) === 0) {
                                result[i].allDay = true;
                                result[i]
                                    .end
                                    .setDate(result[i].end.getDate() - 1);
                            } else {
                                result[i].allDay = false;
                            }
                            events.push(result[i]);
                        }
                    }
                    events
                        .sort(function (a, b) {
                            var dateA = new Date(a.start),
                                dateB = new Date(b.start);
                            return dateA - dateB;
                        });
                    that.setState({searchData: events, isLoaded: true});
                }, function (reason) {
                    console.log('Error: ' + reason.result.error.message);
                    Swal.fire({icon: 'error', text: 'Search bar is not functioning at this time.', footer: '<sub>Please contact:<a id="error-contact" href="mailto:contact@craftottawa.ca" rel="author" alt="email link for contact@craftottawa.ca">contact@craftottawa.ca</a></sub>'})
                })
        }
        setTimeout(function () {
            gapi.load('client', start);
        }, 100);

        function checkDate(date) {
            let result = new Date();
            let checkDate = date;
            if (checkDate.dateTime === undefined) {
                result = new Date(checkDate.date.split('-').join('/'));
            } else {
                result = new Date(checkDate.dateTime);
            }
            return result;
        }
    }

    activateModal = () => {
        this.setState({modalVisible: true});
    };

    deactivateModal = () => {
        this.setState({modalVisible: false, value: '', suggestions: []});
    };

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let suggestions = this.state.searchData;

        return inputLength === 0 ? [] : suggestions.filter(event =>
            event.summary.replace('Ç', 'C').replace('À', 'A').replace('Â', 'A').replace('É', 'E').replace('È', 'E').replace('Ê', 'E').replace('Ë', 'E').replace('Ï', 'I').replace('Î', 'I').replace('Ô', 'O').replace('Ù', 'U').replace('Û', 'U').replace('Ü', 'U').replace('Ÿ', 'Y').replace('ç', 'c').replace('à', 'a').replace('â', 'a').replace('é', 'e').replace('è', 'e').replace('ê', 'e').replace('ë', 'e').replace('ï', 'i').replace('î', 'i').replace('ô', 'o').replace('ù', 'u').replace('û', 'u').replace('ü', 'u').replace('ÿ', 'y').toLowerCase().indexOf(inputValue) >= 0 || event.location.split(',')[0].replace('Ç', 'C').replace('À', 'A').replace('Â', 'A').replace('É', 'E').replace('È', 'E').replace('Ê', 'E').replace('Ë', 'E').replace('Ï', 'I').replace('Î', 'I').replace('Ô', 'O').replace('Ù', 'U').replace('Û', 'U').replace('Ü', 'U').replace('Ÿ', 'Y').replace('ç', 'c').replace('à', 'a').replace('â', 'a').replace('é', 'e').replace('è', 'e').replace('ê', 'e').replace('ë', 'e').replace('ï', 'i').replace('î', 'i').replace('ô', 'o').replace('ù', 'u').replace('û', 'u').replace('ü', 'u').replace('ÿ', 'y').toLowerCase().indexOf(inputValue) >= 0 || event.location.split(',')[2].replace('Ç', 'C').replace('À', 'A').replace('Â', 'A').replace('É', 'E').replace('È', 'E').replace('Ê', 'E').replace('Ë', 'E').replace('Ï', 'I').replace('Î', 'I').replace('Ô', 'O').replace('Ù', 'U').replace('Û', 'U').replace('Ü', 'U').replace('Ÿ', 'Y').replace('ç', 'c').replace('à', 'a').replace('â', 'a').replace('é', 'e').replace('è', 'e').replace('ê', 'e').replace('ë', 'e').replace('ï', 'i').replace('î', 'i').replace('ô', 'o').replace('ù', 'u').replace('û', 'u').replace('ü', 'u').replace('ÿ', 'y').toLowerCase().indexOf(inputValue) >= 0
        );
    }

    getSuggestionValue = suggestion => suggestion.summary;

    checkSuggestionDate = suggestion => {
        if (suggestion.allDay) {
            if ((Math.abs(suggestion.end - suggestion.start) / 36e5) === 0) {
                return (moment(suggestion.start).format('ddd MMM Do'));
            } else {
                return (moment(suggestion.start).format('MMM Do') + " - " + moment(suggestion.end).format('MMM Do'));
            }
        }
        else if (suggestion.start.toDateString() === suggestion.end.toDateString()) {
            return (moment(suggestion.start).format('ddd MMM Do'));
        } else {
            return (moment(suggestion.start).format('MMM Do') + " - " + moment(suggestion.end).format('MMM Do'));
        }
    }

    renderSuggestion = suggestion => (
        <div>
            <span className='bold'>{suggestion.summary}</span><span className='bold'>, {this.checkSuggestionDate(suggestion)}</span><span> at </span><span>{suggestion.location.split(',')[0] + ", " + suggestion.location.split(', ')[2] + " " + suggestion.location.split(', ')[3].slice(0,2)}</span>
        </div>
        );

    onChange = (event, { newValue }) => {
        this.setState({
                value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, { suggestion}) => {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.gotoDate(suggestion.start);
        this.setState({
            chosenEvent: {
                id: suggestion.id,
                title: suggestion.summary,
                startDate: suggestion.start,
                startTime: suggestion.start.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit'})
                        .replace(/^0(?:0:0?)?/, ''),
                endDate: suggestion.end,
                endTime: suggestion.end.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'})
                    .replace(/^0(?:0:0?)?/, ''),
                allDay: suggestion.allDay,
                location: suggestion.location,
                locationName: suggestion.location.split(',')[0],
                imageLink: suggestion.description.substring(suggestion.description.lastIndexOf('{') + 1, suggestion.description.lastIndexOf('}')),
                description: this.stringToHTML(suggestion.description.split('///')[0])
            }
        });
        this.activateModal();
    }

    isEndDateNull = val => {
        if (val.end === null) {
            return 'same as start date';
        } else if (val.allDay) {
            if ((Math.abs(val.end - val.start) / 36e5) === 24) {
                return val.start;
            } else {
                let endDate = val.end;
                endDate.setDate(endDate.getDate() - 1);
                return endDate;
            }
        } else {
            return val.end;
        }
    }

    isEndTimeNull = val => {
        if (val === null) {
            return 'no end time';
        } else {
            return val
                .toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit'
            }).replace(/^0(?:0:0?)?/, '');
        }
    }
    
    stringToHTML = val => {
        const clean = DOMPurify.sanitize(val);
        return clean;
    }

    addTooltip = info => {
        if (info.event.extendedProps.location !== undefined) {
            let location = info.event.extendedProps.location.split(',')[0] + ',' + info.event.extendedProps.location.split(',')[2] + " " + info.event.extendedProps.location.split(', ')[3].slice(0,2);
            $(info.el).tooltip({ 
                title: location,
                placement: 'top',
                trigger: 'hover',
                container: 'body'
            }).on('click', function () {
                $(this).tooltip('hide')
            }).on('blur', function () {
                $(this).tooltip('hide')
            });
        }
    }

    handleEventClick = (eventClickInfo) => {
        if (eventClickInfo.el.className.includes("holidays-ontario")) {
            eventClickInfo.jsEvent.preventDefault(); // prevents redirect to Google Calendar
        } else {
            eventClickInfo.jsEvent.preventDefault(); // prevents redirect to Google Calendar
            this.setState({
                chosenEvent: {
                    id: eventClickInfo.event.id,
                    title: eventClickInfo.event.title,
                    startDate: eventClickInfo.event.start,
                    startTime: eventClickInfo
                        .event
                        .start
                        .toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit'
                        }).replace(/^0(?:0:0?)?/, ''),
                    endDate: this.isEndDateNull(eventClickInfo.event),
                    endTime: this.isEndTimeNull(eventClickInfo.event.end),
                    allDay: eventClickInfo.event.allDay,
                    location: eventClickInfo.event.extendedProps.location,
                    locationName: eventClickInfo.event.extendedProps.location.split(',')[0],
                    imageLink: eventClickInfo.event.extendedProps.description.substring(eventClickInfo.event.extendedProps.description.lastIndexOf('{') + 1, eventClickInfo.event.extendedProps.description.lastIndexOf('}')),
                    description: this.stringToHTML(eventClickInfo.event.extendedProps.description.split('///')[0])
                }
            });
            this.activateModal();
        }
    }

    render() {
        const { value, suggestions, modalVisible } = this.state;

        const inputProps = {
            placeholder: 'Search by event title, venue, or town',
            value,
            onChange: this.onChange
        };

        return (
            <Fragment>
                <Helmet>
                    <title>Craft Ottawa - Events &amp; Festivals</title>
                    <meta name="description" content="Craft Ottawa's Events &amp; Festivals Calendar."/>
                    <meta property="og:title" content="Craft Ottawa - Events &amp; Festivals"/>
                    <meta
                        property="og:description"
                        content="Craft Ottawa's Events &amp; Festivals Calendar."/>
                </Helmet>
                <div id='events-calendar' className='container'>
                <div className="search">
                    <div className="search__inner">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                            focusInputOnSuggestionClick={false}
                            onSuggestionSelected={this.onSuggestionSelected}
                        />
                    </div>
                </div>
                    <FullCalendar
                        id='fullCalendar'
                        ref={this.calendarRef}
                        titleFormat={{
                        year: 'numeric',
                        month: 'short'}}
                        customButtons={{
                        listEvent: {
                            text: 'Submit an Event',
                            click: () => {this.props.navigate('/addevents')}
                        }
                        }}
                        header={{
                        left: 'dayGridMonth,listMonth today',
                        center: 'title prev,next',
                        right: 'listEvent'}}
                        height='auto'
                        fixedWeekCount={false}
                        plugins={[dayGridPlugin, listPlugin, googleCalendarPlugin]}
                        googleCalendarApiKey={'AIzaSyAnbfHuZJAyJVajBlHYiH2nyH_96xrebq4'}
                        eventSources={[{
                            googleCalendarId: '9hc8npmpfgko5prmrpal42j7bg@group.calendar.google.com',
                            className: 'ottawa-events',
                            id: '0',
                            color: '#0074D9'
                        }, {
                            googleCalendarId: 'sypniewski.info_49ida5795cpm45j3klolsh1mec@group.calendar.google.com',
                            className: 'holidays-ontario',
                            id: '1',
                            allDayDefault: true,
                            color: 'green'
                        }]}
                        eventRender={this.addTooltip}
                        eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'narrow'}}
                        eventClick={this.handleEventClick}
                    />
                </div>
                {modalVisible && (<EventPopup
                    popupDetails={this.state.chosenEvent}
                    onHide={this.deactivateModal}/>)}
            </Fragment>
        );
    }
}

export default Events;