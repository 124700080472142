import React from 'react';
import ReactDOM from 'react-dom';

/* js */
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

/* CSS: */
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

/* Others: */
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createHistory} from "@reach/router";
import {LocationProvider} from '@reach/router';

let history = createHistory(window);

const Root = () => (
    <LocationProvider history={history}>
        <App/>
    </LocationProvider>
);

ReactDOM.render(
    <Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change unregister() to register() below.
// Note this comes with some pitfalls. Learn more about service workers:
// https://bit.ly/CRA-PWA
serviceWorker.unregister();