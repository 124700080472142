import React from 'react';
import TwitterBWIcon from './images/twitter-bw.svg';
import EmailBWIcon from './images/email-bw.svg';

class Footer extends React.Component {
    render() {
        return (
            <div id='footer'>
                <span id='copyright'>
                    &#169;2021&thinsp;
                    <a href="https://midworldtech.ca/" rel='author noopener noreferrer' target='_blank'>Mid-World Technologies</a>
                </span>
                <span id='hashtag'>#craftottawa</span>
                <span id='footer-icons'>
                    <a className='social-link' href='mailto:contact@craftottawa.ca' rel='author noopener noreferrer' target='_blank'>
                        <img src={EmailBWIcon} alt="icon link to the email address contact@craftottawa.ca" className='social-link-image'/>
                    </a>
                    <a className='social-link' href='https://twitter.com/craft_ottawa' rel='author noopener noreferrer' target='_blank'>
                        <img src={TwitterBWIcon} alt="icon link to the Craft Ottawa Twitter account" className='social-link-image'/>
                    </a>
                </span>
            </div>
        );
    }
}

export default Footer;